import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import {useHistory, useParams } from "react-router-dom";
import { Icon} from '@iconify/react';
import bookOutlined from '@iconify/icons-ant-design/book-outlined';
import bxCalendar from '@iconify/icons-bx/bx-calendar';
import clockCircleOutlined from '@iconify/icons-ant-design/clock-circle-outlined';
import barChart from '@iconify/icons-bi/bar-chart';
import bcp from "../assets/static/bcp.png"
import bbva from "../assets/static/bbva.png"
import interbank from "../assets/static/interbank.png"
import plin from "../assets/static/plin.png"
import yape from "../assets/static/yape.png"
import "../assets/styles/pages/SuscriptionFroms.scss"

import Swal from 'sweetalert2'
import axios from 'axios'
import { useAuth } from '../context/AuthContext';
import { Alert } from 'react-bootstrap';
import { db } from '../config/Firebase.config';
// import { useAuth } from '../context/AuthContext';

const SuscriptionForm = () => {
  const { currentUser} = useAuth()
  const [disable,setDisable] = useState(false)
  const firstNameRef = useRef()
  const midNameRef = useRef()
  const lastNameRef = useRef()
  const secLastNameRef = useRef()
  const ageRef = useRef()
  const emailRef = useRef()
  const phoneRef = useRef()
  const dnieRef = useRef()

  const history = useHistory()
  const [data,setData] = useState('')
  const [profile,setProfile] = useState("")

  let { route } = useParams();
  const baseUrl = "https://welivic-backend.onrender.com"
  const API = "/courses?route=/"+ route
  const API_Fetch = baseUrl + API
  const API_COURSES = "/course-signeds?uid=" + currentUser.uid

  useEffect(()=>{
    fetch((API_Fetch), {
      method:'GET',
      headers: {'Content-Type': 'application/json'},
    })
      .then(response =>
        response.json()      
      )
      .then(data => {
        // console.log(data[0]['id']);
        setData(data) 
        fetch((baseUrl + API_COURSES), {
          method:'GET',
          headers: {'Content-Type': 'application/json'},
        })
          .then(response =>
            response.json()      
          )
          .then(courses => {
            let coursesTemp = []
            courses.map(i => coursesTemp.push(i["id_course"])) 
            // console.log(coursesTemp);            
            coursesTemp.includes(data[0]['id']) ? setDisable(true) : setDisable(false)
            })  
        })  
    
    const getProfile = async () =>{
      const querySnapshot = await db.collection('users').where("uid", "==",currentUser.uid).get()
      let docProfile = "no-profile"
      querySnapshot.forEach(doc => {
        docProfile = doc.data()
        // console.log(doc.data().email + " este");
      })
      // console.log(querySnapshot);
      setProfile(docProfile);
    }
    getProfile()
  },[API_Fetch, API_COURSES, route, currentUser.uid])

  // const getProfile = async () =>{
  //   const querySnapshot = await db.collection('users').where("email", "==",currentUser.email).get()
  //   let docProfile = "no-profile"
  //   querySnapshot.forEach(doc => {
  //     docProfile = doc.data()
  //     // console.log(doc.data().email + " este");
  //   })
  //   // console.log(querySnapshot);
  //   setProfile(docProfile);
  // }

  // const imgUrl = data.length > 0 ? baseUrl + data[0]["portrait_image"]["url"] :"https://www.mvrdv.nl/media/uploads/22_websize_Tianjin_Library_%C2%A9Ossip_1(5).jpg?width=1920"
  const imgUrl = data.length > 0 ? data[0]["img"] :"https://www.mvrdv.nl/media/uploads/22_websize_Tianjin_Library_%C2%A9Ossip_1(5).jpg?width=1920"

  const imageBackGround = `linear-gradient(180deg, rgba(255, 255, 255, 0) -59.9%, #001631 100%), url('${imgUrl}'), #737395`

  const imageStyle = {
    background: imageBackGround,
    width: "100%",
    height: "250px",
    backgroundSize: "cover",
    color: "white",
  }

  const paymentsLogos = [yape, plin, bcp, bbva, interbank]


  const succesAlert = () =>{
    // document.getElementById("contactForm").reset();
    Swal.fire({
        icon: "success",
        title: 'Excelente!',
        text: 'Te hemos envíado las instrucciones para finalizar tu matrícula',
      // footer: 'Mantente atento a tu correo, te enviaremos una confirmación para la reunión que acabas de agendar'
    })
    
    }

    const errorAlert = () => {
      Swal.fire({
          icon:"error",
          title:'Oops',
          text:'Completa los campos por favor',
          footer:'Si el problema persiste probablemente sea tu conexión a internet, intentalo de nuevo mas tarde'
          })
      }
    const loadingAlert = () => {
    Swal.fire({
        imageUrl : 'https://hawkeyestatic.s3.amazonaws.com/images/2cbb5e95b97aa2b496f6eaec84b9240d.gif',
        title:'Cargando...',
        imageWidth: 300,          
        text:'Un minuto por favor',
        showConfirmButton: false
    })
    }

    async function onSubmit (e) {
      e.preventDefault();
      setDisable(true)
      await axios.post('https://welivic-mail-service.herokuapp.com/api/subcription',{
      // await axios.post('http://localhost:3001/api/subcription',{
        name: firstNameRef.current.value,
        midName: midNameRef.current.value,
        lastName: lastNameRef.current.value, 
        secLastName: secLastNameRef.current.value, 
        age: ageRef.current.value, 
        email: emailRef.current.value, 
        phone: phoneRef.current.value, 
        dni: dnieRef.current.value, 
        course: data.length > 0 ? data[0]['title'] : 'Curso',
      }).then(function (response) {
        // console.log(response);
      
        // succesAlert()
        // history.push("/subscribed")
      }).catch((error)=>{
        errorAlert()
        // console.log(error);
      })   
      await axios.post('https://welivic-backend.onrender.com/course-signeds',{
        final_grade: 0,
        work_grade: 0, 
        certificate: "none",
        exam_grade: 0,
        progress: 0,
        id_course: data[0]['id'],
        uid: currentUser.uid,
        temps: 2,
        email: currentUser.email,
        disable: true,
        name: firstNameRef.current.value + " " + midNameRef.current.value,
        last_name: lastNameRef.current.value + " " + secLastNameRef.current.value,
        title: data.length > 0 ? data[0]['title'] : 'Curso',

      }).then(function (response) {
        // console.log(response);
        // succesAlert()
        // history.push("/subscribed")
      }).catch((error)=>{
        // console.log("error strapi");
        // errorAlert()
        // console.log(error);
      }) 

      await axios.post('https://welivic-mail-service.herokuapp.com/api/payment-steps',{
        name: firstNameRef.current.value,
        email: emailRef.current.value, 
        course: data.length > 0 ? data[0]['title'] : 'Curso',
        price: data.length > 0 ? data[0]['price'] : 'NaN' 
      }).then(function (response) {
        // console.log(response);
        succesAlert()
        history.push("/subscribed")
      }).catch((error)=>{
        // console.log("error 2");
        errorAlert()
        // console.log(error);
      }) 
    
    }

    const showAlert = () =>{   
      function alertEnviado() {
        if ((
          firstNameRef.current.value === '') || 
          !(String(emailRef.current.value).includes('@')) || 
          emailRef.current.value === '' || 
          (midNameRef.current.value  === '') || 
          (lastNameRef.current.value  === '')|| 
          (secLastNameRef.current.value  === '')|| 
          (ageRef.current.value  === '')|| 
          (phoneRef.current.value  === '')|| 
          (dnieRef.current.value  === '')
          
          ) 
          {   
            // errorAlert()       
          }else{          
            loadingAlert()
            
            
          }
        }
      return(
          alertEnviado()    
      )    
    } 


  return (
    <div className="mt-5">
      {/* <h3>route: {route} holi aqui me suscribiré</h3> */}
      <Container>
        <Form onSubmit={onSubmit}>
          
          <Row>
            <Col md={8}>
              <p>Llena esta ficha de inscripción y presiona en "Enviar ficha". Recibiremos tu ficha de inscripción y te llegará un correo con la información de pago e indicaciones para finalizar tu inscripción.</p>
              <h3 className="mt-4 mb-4">Ficha de inscripción a {data.length > 0 ? data[0]["title"] : "Curso"}</h3>
              <Form.Group className="mt-4 mb-4">
                <Form.Label><strong>Primer Nombre</strong></Form.Label>
                <Form.Control type="text" ref={firstNameRef} required className='common-form' placeholder="Escribe tu primer nombre" defaultValue={profile !== "" ? profile.name : ""}/>
              </Form.Group>
              <Form.Group className="mt-4 mb-4">
                <Form.Label><strong>Segundo Nombre</strong></Form.Label>
                <Form.Control type="text" ref={midNameRef} className='common-form' placeholder="Escribe tu segundo nombre (opcional)" defaultValue={profile !== "" ? profile.surename : ""}/>
              </Form.Group>
              <Form.Group className="mt-4 mb-4">
                <Form.Label><strong>Apellido Paterno</strong></Form.Label>
                <Form.Control type="text" ref={lastNameRef} required className='common-form' placeholder="Escribe tu apellido paterno" defaultValue={profile !== "" ? profile.lastname : ""}/>
              </Form.Group>
              <Form.Group  className="mt-4 mb-4">
                <Form.Label><strong>Apellido Materno</strong></Form.Label>
                <Form.Control type="text" ref={secLastNameRef} required className='common-form' placeholder="Escribe tu apellido materno" defaultValue={profile !== "" ? profile.seclastname : ""}/>
              </Form.Group>
              <Form.Group  className="mt-4 mb-4">
                <Form.Label><strong>Edad</strong></Form.Label>
                <Form.Control type="number" ref={ageRef} required className='common-form' placeholder="Escribe edad" defaultValue={profile !== "" ? profile.age : ""}/>
              </Form.Group>
              <Form.Group className="mt-4 mb-4">
                <Form.Label><strong>Email</strong></Form.Label>
                <Form.Control type="email" ref={emailRef}  required className='common-form' placeholder="Escribe tu correo electrónico" defaultValue={currentUser.email}/>
              </Form.Group>
              <Form.Group className="mt-4 mb-4">
                <Form.Label><strong>Teléfono (Whatsapp)</strong></Form.Label>
                <Form.Control type="text" ref={phoneRef} required className='common-form' placeholder="Escribe tu teléfono con whatsapp" defaultValue={profile !== "" ? profile.phone : ""}/>
              </Form.Group>
              <Form.Group className="mt-4 mb-4">
                <Form.Label><strong>DNI</strong></Form.Label>
                <Form.Control type="text" ref={dnieRef} required className='common-form' placeholder="Escribe tu DNI" defaultValue={profile !== "" ? profile.dni : ""}/>
              </Form.Group>
            </Col>
            <Col md={4}>
              {
                data.length > 0 ? 
                <Fragment className="sticky-top">
                <div className="course-resume-image-container" style={imageStyle}>
                  <Row className="p-4">
                    <Col md={8}>
                      <div className="course-resume-image-profesor-continer mb-3 text-center">
                        {/* <img alt="foto-profesor" src={baseUrl + data[0]["teacher"]["picture_profile"]["url"]} className="course-resume-image-profesor fluid "></img> */}
                        <img alt="foto-profesor" src={data[0]["teacher"]["img"]} className="course-resume-image-profesor fluid "></img>
                      </div>  
                      <div className='ad-teacher-text'>
                        <p className='m-0'><strong>Profesor(a):</strong></p>
                        <p className='m-0'>{"Ing " + data[0]["teacher"]['name'] + " " + data[0]["teacher"]['last_name']}</p>              
                      </div>            
                    </Col>
                  </Row>
                  </div>
                  <div className="course-resume-container p-3 m-2 pt-1 mb-4">
                    <h5 className='pt-2 pb-2 course-resume-link'><strong>{data[0]["title"]}</strong></h5>
                    <p >{data[0]["description"]}</p>          
                    <p><Icon icon={bookOutlined} style={{color: '#0075ff', fontSize: '17px'}} /> Metodología: {data[0]["methodoloy"]}</p>
                    <p><Icon icon={bxCalendar} style={{color: '#0075ff', fontSize: '19px'}} /> Fecha de inicio: {data[0]["start_date"]}</p>
                    <p><Icon icon={clockCircleOutlined} style={{color: '#0075ff', fontSize: '19px'}} /> Duración: {data[0]["duration_hours"]} hrs</p>
                    <p><Icon icon={barChart} style={{color: '#0075ff', fontSize: '19px'}} /> Nivel: {data[0]["level"]}</p>
                    <h5 className="text-center pt-2"><strong>Precio</strong></h5>
                    <div className="ad-price pt-3 pb-3 d-flex justify-content-center"> 
                      <h4 className="pr-2 d-flex align-items-center pl-2 ad-comparition-price"><strike><strong>S/. {data[0]["discount_price"]}</strong></strike></h4>
                      <h2 className="pl-2 ad-real-price"><strong>S/. {data[0]["price"]}</strong></h2>
                    </div>    
                    {
                      disable ? <Alert variant="success">Ya estas inscrito en este curso</Alert> : <div></div>
                    }
                    <button className="btn btn-primary btn-block pt-3 pb-3" onClick={showAlert} disabled={disable}><strong>Enviar ficha e Inscribirme</strong></button>
                    <p className="pt-4 mb-4">Realiza tu pago con: </p>
                    <div className="suscription-payment-logos-container">
                      {
                        paymentsLogos.map((i, key)=>(
                          <img alt="logo-payment" src={i} className="suscription-payment-logos" key={key}></img>
                        ))
                      }
                    </div>
                  </div>
                </Fragment>:
                <Skeleton count={5}/>
              }
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default SuscriptionForm;