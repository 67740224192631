import React from 'react';
import { Row ,Col} from 'react-bootstrap';
import "../assets/styles/components/AdFlyer.scss"
import { Icon} from '@iconify/react';
import bookOutlined from '@iconify/icons-ant-design/book-outlined';
import bxCalendar from '@iconify/icons-bx/bx-calendar';
import clockCircleOutlined from '@iconify/icons-ant-design/clock-circle-outlined';
import barChart from '@iconify/icons-bi/bar-chart';

const AdFlyer = (props) => {
  const { 
    imgProf, 
    profName,
    imgAd,
    titleAd,
    methodology,
    initDate,
    duration,
    level,
    comparitionPrice,
    realPrice

  } = props;

  const imgUrl = imgAd ? imgAd :"https://www.mvrdv.nl/media/uploads/22_websize_Tianjin_Library_%C2%A9Ossip_1(5).jpg?width=1920"

  const imageBackGround = `url('${imgUrl}')`

  const imageStyle = {
    background: imageBackGround,
    width: "100%",
    height: "250px",
    backgroundSize: "cover",
    color: "white",
  }

  return (
    <div className='ad-continer p-5'>
        <div className='container'>
      <Row className=' mt-5 mb-5' >
          
          <Col md={6}>
            <Row>
              <Col md={2}>
                <div className="ad-image-professor-continer mb-3 text-center" >
                  <img alt="foto-profesor" src={imgProf} className="ad-image-profesor fluid "></img>
                </div>              
              </Col>
              <Col md={10} className="mb-3">
                <div className='ad-teacher-text ml-4'>
                  <p className='m-0'><strong>Profesor(a):</strong></p>
                  <p className='m-0'>Ing {profName}</p>
                  
                </div>
              </Col>
            </Row>
              <div className="ad-image-course-container  text-center" style={imageStyle}>
                {/* <img alt="course-foto" src={imgAd} className="w-100 fluid mb-3 ad-image-course"></img>               */}
              </div>
          </Col>
          <Col md={6} className="ad-continer-left">
            <h2 className="pb-3"><strong>{titleAd}</strong></h2>
            <p>Metodología: {methodology}<Icon icon={bookOutlined} style={{color: '#ffd600', fontSize: '20px'}} /></p>
            <p>Fecha de inicio: {initDate} <Icon icon={bxCalendar} style={{color: '#ffd600', fontSize: '20px'}} /></p>
            <p>Duración: {duration} hrs <Icon icon={clockCircleOutlined} style={{color: '#ffd600', fontSize: '20px'}} /></p>
            <p>Nivel: {level} <Icon icon={barChart} style={{color: '#ffd600', fontSize: '16px'}} /></p>
            <div className="ad-price pt-3"> 
              <h4 className="pr-2 d-flex align-items-center pl-2 ad-comparition-price"><strike><strong>S/. {comparitionPrice}</strong></strike></h4>
              <h2 className="pl-2 ad-real-price"><strong>S/. {realPrice}</strong></h2>
            </div>
          </Col>
      </Row>
        </div>
    </div>
  );
};

export default AdFlyer;