import React from 'react';
import FooterComponent from './shared/FooterComponent';
import NavBarComponent from './shared/NavBarComponent';
// children es una palabra reservada para layout
const Layout = ({ children }) => (
  <div className="App">
    <NavBarComponent/>
    {/* <div className="mt-5 pt-5">
    </div> */}
      {children}
    <FooterComponent/>
  </div>
);

export default Layout;