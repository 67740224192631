import React,{useState} from 'react';
import { Col, Row } from 'react-bootstrap';
import ModalVideo from 'react-modal-video'
import "../assets/styles/components/Seminar.scss"

const Seminar = (props) => {
  const {
    idVideo,
    title,
    teacherName,
    teacherImg
  } = props
  const [isOpen, setOpen] = useState(false)
  return (
    <div >
      <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={idVideo} onClose={() => setOpen(false)} /> 
      <div onClick={()=> setOpen(true)}>        
        <div className="seminar-container m-3" >
          <Row className="p-4">
            <Col md={8} className="seminar-text-container">
            <h6><strong>{title}</strong></h6>            
              <div className='ad-teacher-text'>
                <p className='m-0'><strong>Ponente:</strong></p>
                <p className='m-0'>{teacherName}</p>              
              </div>            
            </Col>
            <Col md={4} className="text-center p-0">
            <div className="seminar-image-profesor-continer mb-3 text-center">
                <img alt="foto-profesor" src={teacherImg ? teacherImg  : "https://iconos8.es/icon/43254/training" } className="course-resume-image-profesor fluid "></img>
              </div>  
            </Col> 
            
            {/* <button className="btn-info btn-block" onClick={()=> setOpen(true)}>Ver seminario</button>  */}
          </Row>
          </div>
      </div>
  

    </div>
  );
};

export default Seminar;