import React from 'react';
import SeminarGrid from '../containers/SeminarGrid';

const Seminars = () => {
  return (
    <div className="mb-5 pb-5">
      <SeminarGrid titleSection="Todos nuestros Seminarios" query="?_sort=published_at:DESC"></SeminarGrid>
    </div>
  );
};

export default Seminars;