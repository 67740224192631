import React, { useEffect, useState } from 'react';
import {useParams } from "react-router-dom";
// import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Skeleton from 'react-loading-skeleton';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import BookIcon from '@material-ui/icons/Book';
// import ReactPlayer from 'react-player';
import { Container } from 'react-bootstrap';

// import { useMediaQuery } from 'react-responsive'
import Lessons from '../containers/Lessons';
import { Link as LinkS} from 'react-scroll'


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const CourseLessons = (props) => {
  let { course_route } = useParams();
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [data,setData] = useState('')
  const [chapters,setChapters] = useState([])
  const [mobileOpen, setMobileOpen] = useState(false);

  const baseUrl = "https://welivic-backend.onrender.com"
  const API = "/courses?route=/"+ course_route
  const API_Fetch = baseUrl + API
  
  useEffect(()=>{
    
    fetch((API_Fetch), {
      method:'GET',
      headers: {'Content-Type': 'application/json'},
    })
      .then(response =>
        response.json()      
      )
      .then(data => {
        // console.log(data[0]['id']);
        setData(data)
        const urlChapters = "https://welivic-backend.onrender.com/chapters?_sort=order_number:asc&course=" + data[0]['id']
        fetch((urlChapters), {
          method:'GET',
          headers: {'Content-Type': 'application/json'},
        }).then(response =>
          response.json()      
        )
        .then(data1 => {
          // console.log(data1);
          setChapters(data1)}
        )
        
      })

    
  },[API_Fetch])


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <ListItem button >
            <Link to="/mycourses" className='btn btn-outline-info d-flex align-items-center'>
            {/* <ListItemIcon> */}
              {<ArrowBackIosIcon />}
            {/* </ListItemIcon> */}
              Volver a mis cursos
                {/* <ListItemText primary={"Volver a mis cursos"} /> */}
            </Link>
      </ListItem>
      <Divider />
      {
        chapters.length > 0 ? 
          chapters.map((i,key) => (
            <div key={key}>
              <ListItem button  className="lesson-item-nav">
                <ListItemIcon>{<ImportContactsIcon  color="primary"/>}</ListItemIcon>
                <LinkS 
                activeClass="active"
                to={"chapter"+ key} spy={true} smooth={true} duration={500}>
                  <ListItemText primary={"Capítulo "+ (key + 1) + ": " + i['title']} />
                </LinkS>
              </ListItem>
              {
                i["lessons"].map((index,keyL) => (
                  <ListItem button key={keyL}>
                    <ListItemIcon>{<BookIcon/>}</ListItemIcon>
                    <LinkS 
                    activeClass="active"
                    to={"lesson"+ key  + "."+ keyL} spy={true} smooth={true} duration={500}>
                      <ListItemText primary={"Lección" +(key+1) + "." +(keyL + 1) + ": " + index['title']}/>                      
                    </LinkS>
                  </ListItem>
                ))
              }
          </div>
          ))
        : <Skeleton></Skeleton>
      }      
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
      
      <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
            {
              data.length > 0 ? 
              <h4 variant="h6" className="mt-4 mb-4">
                  {data[0]['title']} 
              </h4> 
              : <Skeleton></Skeleton>
              }
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container>
        {
          chapters.length > 0 ?
            <Lessons chapters={chapters} course={data[0]}></Lessons> 
          :
          <>
          <Skeleton height={250} count={1}/>
          <Skeleton count={25}></Skeleton>
          </>
        }
        </Container>
      </main>
    </div>
    </div>
  );
  
};



export default CourseLessons;