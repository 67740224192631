import { Container } from '@material-ui/core';
import React, {useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Quiz from 'react-quiz-component';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../context/AuthContext';
import '../assets/styles/pages/Test.scss'
import axios from 'axios'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const Test = () => {
  let { course_route } = useParams();
  const { currentUser} = useAuth()
  const [botonContinuar,setBotonContinuar] = useState(false)
  const [data,setData] = useState('')
  const [courseSigned, setCourseSigned] = useState([])

  const baseUrl = "https://welivic-backend.onrender.com"
  const API = "/courses?route=/"+ course_route
  const API_Fetch = baseUrl + API
  const API_COURSE_SIGNED = baseUrl + "/course-signeds?id_course="
  
  useEffect(()=>{
    
    fetch((API_Fetch), {
      method:'GET',
      headers: {'Content-Type': 'application/json'},
    })
      .then(response =>
        response.json()      
      )
      .then(data => {
        // console.log(data);
        setData(data)   
        fetch((API_COURSE_SIGNED + data[0]['id'] + "&uid="+currentUser.uid), {
          method:'GET',
          headers: {'Content-Type': 'application/json'},
        })
          .then(response =>
            response.json()      
          )
          .then(data => {
            // console.log(data)
            setCourseSigned(data)
          })
      })
  },[API_Fetch, API_COURSE_SIGNED,currentUser.uid])
  
  const onCompleteAction = async (obj) => {
    // console.log(obj);
    // console.log(obj.correctPoints);
    console.log(courseSigned[0]['id']);
    await axios.put('https://welivic-backend.onrender.com/course-signeds/' + courseSigned[0]['id'],{
        temps: courseSigned[0]['temps'] - 1,
        exam_grade: obj["correctPoints"] > courseSigned[0]['exam_grade'] ? obj["correctPoints"] : courseSigned[0]['exam_grade']
      }).then(function (response) {
        // console.log(response);
        // succesAlert()
        // history.push("/subscribed")
      }).catch((error)=>{
        // console.log("error strapi");
        // errorAlert()
        // console.log(error);
      }) 
    setBotonContinuar(true)
    return obj["correctPoints"]
    // YOUR LOGIC GOES HERE
  }
  return (
    <div>
      <Container className="mb-5 pb-5 text-container">
      <div className="mt-4">
        <Link to="/mycourses"className='btn btn-outline-info'><h6 className='m-0'><ArrowBackIosIcon />  Volver a mis cursos</h6></Link>

      </div>
          {
            courseSigned.length ?
            <Row>
            <Col md={8}>
              <Alert className="mt-5 mb-5" variant="info">
                <h3 >Evaluación final {data[0]['title']}</h3>
              </Alert>
            </Col>
            <Col md={4}>
              <Alert className="mt-5 mb-5" variant="info">
              {
                courseSigned[0]['exam_grade'] !== "0" ?
                <h3 >Nota final: <strong>{courseSigned[0]['exam_grade']} puntos</strong></h3>
                :
                <h3>Sin calificar</h3>
              }
              </Alert>
            </Col>
            </Row>
            : <Skeleton count={3}></Skeleton>
          }
        <Row>
          <Col md={5}>
          {
            courseSigned.length?
            <div className="sticky-top">
            <Alert  variant="primary">
              <h3>Puntaje mínimo para aprobar </h3>
              <h3><strong>{Math.floor(data[0]["exam"]["questions"].length * 0.8)} de {data[0]["exam"]["questions"].length}</strong></h3>
              <h4 className="mt-3 mb-3">Cantidad de intentos restantes <strong>{courseSigned[0]['temps']} de 2</strong></h4>
            </Alert>
            {
              courseSigned[0]['exam_grade'] > 0 ?
              <Alert  variant="primary">
                <h5>Nota final: <strong>{courseSigned[0]['exam_grade']} puntos</strong></h5>
              </Alert> :
              <></>
            }
            {
              botonContinuar ?
              <div>
                {/* <p>Nota final {onCompleteAction()} </p> */}
                <form>
                <button className="btn btn-primary btn-block" type="submit" disabled={courseSigned[0]['temps']<= 0 ? true : false}>{courseSigned[0]['temps'] === 1  ? "Finalizar intento":"Intentar de Nuevo"}</button>
                </form>
              </div>
              : <div></div>
            }
            </div>
            : <Skeleton count={3}></Skeleton>
          }
            
          </Col>
          <Col md={7} className="d-flex justify-content-center quiz-container">
          {
            courseSigned.length?
            
            courseSigned[0]['temps'] > 0 ? 
              <Quiz quiz={data[0]["exam"]} shuffle={true} onComplete={onCompleteAction}/>
              :
              <div className="mt-3">
              <Alert variant="secondary"><h5 className="text-center ">Ya no quedan mas intentos</h5></Alert>            

              </div>
            : <Skeleton count={15}></Skeleton>
          }
          </Col>
        </Row>

      </Container>

    </div>
  );
};

export default Test;