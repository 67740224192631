import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
// import { withStyles } from '@material-ui/core/styles';
// import LinearProgress from '@material-ui/core/LinearProgress';
import Skeleton from 'react-loading-skeleton';
import '../assets/styles/components/MyCourse.scss'
import SchoolIcon from '@material-ui/icons/School';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import BookIcon from '@material-ui/icons/Book';

// const BorderLinearProgress = withStyles((theme) => ({
//   root: {
//     height: 10,
//     borderRadius: 5,
//   },
//   colorPrimary: {
//     backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
//   },
//   bar: {
//     borderRadius: 5,
//     backgroundColor: '#1a90ff',
//   },
// }))(LinearProgress);


const MyCourse = (props) => {

  const [data,setData] = useState([])
  const [aprove, setAprove] = useState("")
  const [grade, setGrade] = useState(0)

  const { 
    id_course,
    // progress,
    // finalGrade,
    workGrade,
    examGrade,
    disable
  } = props; 
  const baseUrl = "https://welivic-backend.onrender.com"
  const API_COURSES = "/courses?id="
  useEffect(()=>{
    fetch((baseUrl + API_COURSES + id_course), {
      method:'GET',
      headers: {'Content-Type': 'application/json'},
    }).then(response =>
      response.json()
    
    ).then(data =>{
      setData(data)
      if (data[0]["final_work"] && data[0]["exam"]) {
        setGrade(workGrade * 0.7 +  examGrade*0.3)
        if(workGrade * 0.7 +  examGrade*0.3 >=14){
          setAprove("success")
        }else if(workGrade * 0.7 +  examGrade*0.3 === 0){
          setAprove("secondary")
        
        }else if(workGrade * 0.7 +  examGrade*0.3 <14){
          setAprove("danger")
        }
      }else if (data[0]["final_work"]) {
        setGrade(workGrade)
        if(workGrade >=14){
          setAprove("success")
        }else if(workGrade < 14 && workGrade > 0){
          setAprove("danger")
        }else{
          setAprove("secondary")
        }
      }else if (data[0]["exam"] ) {
        setGrade(examGrade)
        if(examGrade >=14){
          setAprove("success")
        }else if(examGrade < 14 && examGrade > 0){
          setAprove("danger")
        }else{
          setAprove("secondary")
          
        }
      }  
    })
  },[id_course, examGrade,workGrade])

  // const imgUrl = data.length > 0 ? (baseUrl +data[0]["portrait_image"]["url"]) :"https://www.mvrdv.nl/media/uploads/22_websize_Tianjin_Library_%C2%A9Ossip_1(5).jpg?width=1920"
  const imgUrl = data.length > 0 ? (data[0]["img"]) :"https://www.mvrdv.nl/media/uploads/22_websize_Tianjin_Library_%C2%A9Ossip_1(5).jpg?width=1920"

  const imageBackGround = `linear-gradient(180deg, rgba(255, 255, 255, 0) -59.9%, #001631 100%), url('${imgUrl}'), #737395`

  const imageStyle = {
    background: imageBackGround,
    width: "100%",
    height: "290px",
    backgroundSize: "cover",
    color: "white",
  }


  return (
    <div>
      {
        data.length > 0 ?
        <Fragment>
          <div className="row pb-5">
            <div className="col-md-4">
              <div className="course-resume-image-container" style={imageStyle}>
              <Row className="p-4">
                <Col md={12} className='d-flex justify-content-center align-items-center flex-column'>
                  <div className="course-resume-image-profesor-continer mb-3 text-center">
                    {/* <img alt="foto-profesor" src={baseUrl + data[0]["teacher"]["picture_profile"]["url"]} className="course-resume-image-profesor fluid "></img> */}
                    <img alt="foto-profesor" src={data[0]["teacher"]["img"]} className="course-resume-image-profesor fluid "></img>
                  </div>  
                  <div className='ad-teacher-text'>
                    <p className='m-0'><strong>Profesor(a):</strong></p>
                    <p className='m-0'>{data[0]["teacher"]["name"] + " "+ data[0]["teacher"]["last_name"]}</p>              
                  </div> 
                  <h5 className='pt-2 pb-2 course-resume-link text-center'><strong>{data[0]["title"]}</strong></h5>           
                </Col>          
              </Row>
              </div>              
            </div>
            <div className="col-md-8">
              <div className="course-resume-container p-3 m-2 pt-1 mb-4">
                
                {/* <BorderLinearProgress variant="determinate" value={progress > 100 ? 100 : progress}  /> 
                <p className="text-right">{progress > 100 ? 100 : progress}%</p> */}
                {
                  disable === true ? 
                  <Alert variant="info">Realiza el pago correspondiente para habilitar este curso</Alert>
                  :<div></div>
                }
                <div className="row">
                  <div className="col-md-6 d-flex justify-content-center flex-column">
                      <h5>Acciones: </h5>
                      <button className="btn btn-primary btn-block pt-2 pb-2 mt-4 mb-2" disabled={disable ? true : false }>
                        <Link to={"/mycourses"+  (disable === false ? data[0]["route"] : "")} style={{"color":"white"}}><BookIcon></BookIcon>  <strong>{"Ver Contenido"}</strong></Link>
                      </button>         
                        {
                          data[0]["final_work"] ?
                          <button className="mycourse-outline-button btn btn btn-outline-primary btn-block pt-2 pb-2 mt-4 mb-2" disabled={disable ? true : false }>

                            <Link to={"/mycourses"+  (disable === false ? (data[0]["route"] + "/work" ) : "") } ><MenuBookIcon></MenuBookIcon> <strong>{"Trabajo Practico"}</strong></Link>
                          </button>
                          :
                          <></>         
                        }
                        {
                          data[0]["exam"] ?
                          <button className="mycourse-outline-button btn btn btn-outline-primary btn-block pt-2 pb-2 mt-4 mb-2" disabled={disable ? true : false }>
                            <Link to={"/mycourses"+  (disable === false ? (data[0]["route"] + "/test") : "") } ><SchoolIcon></SchoolIcon> <strong>{"Evaluación final"}</strong></Link>
                          </button>         
                          :
                          <></> 
                        }

                  </div>
                  <div className="col-md-6">
                    <div className="mycourse-grades mt-4">
                      {/* <Alert variant="info"><h5 className="m-0">Notas:</h5></Alert> */}
                      {
                        data[0]["final_work"] ?
                        <h5>Trabajo practico: <strong>{workGrade === "0" ? "Sin calificar" : workGrade + " puntos"}</strong></h5>
                        :<></> 
                      }
                      {
                        data[0]["exam"] ?
                        <h5>Evaluación final: <strong>{examGrade === "0" ? "Sin calificar" : examGrade + " puntos"}</strong></h5>                
                        :<></> 
                      }

                    </div>
                    <h5 className="mt-4 text-center">Promedio final:</h5> 
                  <Alert variant={aprove} className="mt-4">
                    {workGrade === "0"&& examGrade === "0" 
                      ? 
                      <h4 className=" text-center">Sin calificar</h4>
                      :
                      <h2 className=" text-center"><strong>
                      {grade}
                    </strong> de 20</h2>
                    }
                  </Alert>

                  </div>
                </div>
              </div>          
            </div>
              

          </div>
        </Fragment>
        : 
        <Fragment>
          <Row>
              <Col md={4} >
                <Skeleton height={150} count={1}/>
                    </Col>
                <Col md={8}>
                <Skeleton count={7}/>
              </Col>
            </Row>
        </Fragment>
      }
    </div>
  );
};

export default MyCourse;