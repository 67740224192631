import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Col,Row} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import Seminar from '../components/Seminar';
import '../assets/styles/pages/SeminarGrid.scss'

const SeminarGrid = (props) => {
  const [data,setData] = useState([])
  const {
    titleSection,
    query,
    isHome
  } = props

  const baseUrl = "https://welivic-backend.onrender.com"
  const API = "/seminarios"+query
  useEffect(()=>{
    fetch((baseUrl + API), {
      method:'GET',
      headers: {'Content-Type': 'application/json'},
    })
      .then(response =>
        response.json()
      
      )
      .then(data => {
        // console.log(data);
        setData(data)
      })
  },[API])

  
  // const seminarios = [1,2,3,4,5,6,7]
  return (
    <div className="mt-3 pt-2 mb-3">
      <div className={ isHome ? 'container' : 'seminar_container mr-5 ml-5'}>        
        <h1 className="text-center pt-5 pb-5"><strong>{titleSection}</strong></h1>
        <Row>
        {
          data.length > 0 ?          
            data.map((item, key) =>(
              <Col md={isHome ? 6 : 4} key={key}>       
                <Seminar 
                  idVideo = {item["id_youtube"]}
                  title = {item["title"]}
                  teacherName = {item["teacher_name"] + " " + item["teacher_surname"]}
                  // teacherImg = {item["teacher_pic"]["url"] ? baseUrl + item["teacher_pic"]["url"] :"https://iconos8.es/icon/43254/training"}
                  teacherImg = {item["img"] ? item["img"] :"https://iconos8.es/icon/43254/training"}
                ></Seminar> 
              </Col>
            ))        
            : 
              <Fragment>
                <Col md={isHome ? 6 : 4}>
                  <Skeleton count={15}/>
                </Col>              
                <Col md={isHome ? 6 : 4}>
                  <Skeleton count={15}/>
                </Col>              
              </Fragment>
              /* <div className="d-flex justify-content-center">
            
                <Spinner animation="grow" variant="primary" />
              </div>             */
        } 
        </Row>
        {
          isHome=== true ?
          <div className="d-flex justify-content-center mt-5">
            <Link to="/seminarios" className="btn btn-primary pt-3 pb-3 mt-5 mb-5 pl-5 pr-5">
              {/* <button > */}
                Ver todos los seminarios

              {/* </button> */}

            </Link>

          </div>:
          <div></div>

        }
      </div>
    </div>
  );
};

export default SeminarGrid;