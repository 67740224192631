// import React from 'react';
import React, {useState } from 'react';
// import { Container} from 'react-bootstrap';
import { Col, Container, Form, Row} from 'react-bootstrap';
import '../assets/styles/pages/Subscribed.scss'
import ImgEmail from "../assets/static/emailSend.png"
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'
import axios from 'axios'


const SubscribedView = () => {
  const [email, setEmail] =useState("")
  const history = useHistory()
  // const resendEmailRef = useRef()

  const succesAlert = () =>{
    // document.getElementById("contactForm").reset();
    Swal.fire({
        icon: "success",
        title: 'Excelente!',
        text: 'Te hemos envíado las instrucciones para finalizar tu matrícula',
      // footer: 'Mantente atento a tu correo, te enviaremos una confirmación para la reunión que acabas de agendar'
    })
    
    }

    const errorAlert = () => {
      Swal.fire({
          icon:"error",
          title:'Oops',
          text:'Completa los campos por favor',
          footer:'Si el problema persiste probablemente sea tu conexión a internet, intentalo de nuevo mas tarde'
          })
      }
    const loadingAlert = () => {
    Swal.fire({
        imageUrl : 'https://hawkeyestatic.s3.amazonaws.com/images/2cbb5e95b97aa2b496f6eaec84b9240d.gif',
        title:'Cargando...',
        imageWidth: 300,          
        text:'Un minuto por favor',
        showConfirmButton: false
    })
    }

    async function onSubmit (e) {
      e.preventDefault();
      await axios.post('https://welivic-mail-service.herokuapp.com/api/payment-steps-generic',{
        email: email, 
      }).then(function (response) {
        // console.log(response);
        history.push("/")
        succesAlert()
      }).catch((error)=>{
        // console.log("error 2");
        errorAlert()
        // console.log(error);
      }) 
    }
    const showAlert = () =>{   

      function alertEnviado() {
          if ((
            email === '')             
            ) 
            {   
              // errorAlert()       
          }else{          
              loadingAlert()
              
          }
      }
      return(
          alertEnviado()    
      )    
    } 
  
  return (

      <Container>
        <div className="subscribed-container mt-5">
          <h4 className="text-center"><strong>Revisa tu bandeja de entrada!</strong></h4>        
          <div className="subscribed-image-container">
            <img alt="email-sended" src={ImgEmail} className="w-100"></img>
          </div>
          <h5 className="p-4 text-center"><strong>Recibiras un correo con las instrucciones de pago para finalizar tu inscripción</strong></h5>
          <h5>¿No te llegó aún el correo?</h5>
          <Form className="text-center" onSubmit={onSubmit}>
            <Form.Group >
                <Form.Control type="email"  
                onChange={(e)=>setEmail(e.target.value)} 
                // ref={resendEmailRef}
                required className='common-form' placeholder="Correo electrónico" />
            </Form.Group>
            <button className="btn btn-light " onClick={showAlert}>Reenviar Correo a:</button>{email}
          </Form>
          <h6 className="p-4 text-center">Si tienes alguna otra duda, comunícate con nosotros en 
            <strong><a href="mailto: team@welivic.com" target = "_blank" rel="noreferrer"> team@welivic.com</a></strong> o en
            <strong><a href="mailto: team@welivic.com" target = "_blank" rel="noreferrer"> info@welivic.com</a></strong> 
          </h6>
        </div>
        <Row>
          <Col md={6}>
          <div>
            <Link to={"/"}><button className="btn btn-outline-primary btn-block mt-3 mb-3 pt-3 pb-3" ><strong>Inicio</strong></button></Link>
          </div>
          </Col>
          <Col md={6}>
          <div>
            <Link to={"/mycourses"}><button className="btn btn-primary btn-block mt-3 mb-3 pt-3 pb-3" ><strong>Ir a mis cursos</strong></button></Link>
          </div>

          </Col>
        </Row>
              
      </Container>
  );
};

export default SubscribedView;