import React from 'react';

const StepStatic3 = () => {
  return (
    <div>
      <p>Sigue estas indicaciones para certificarte con <strong>WeLivic</strong></p>
      <ol>
              <li>Aprende con el contenido de tus cursos.</li>            
              <li>Entrega tu trabajo final en <strong>Google Classroom</strong>.</li>
              <li>Realiza la evaluación final con nota <strong>aprobatoria</strong>.</li>
              <li>Podras ver tus resultados en el resumen del <strong>curso</strong>.</li>
              <li>Obtendremos tus resultados y <strong> enviaremos tu certificado a tu correo</strong>.</li>
              <li>Si haz culminado el curso satisfactoriamente y no has recibido tu certificado en los próximos 7 días puedes escribirnos a <strong> team@welivic.com</strong></li>
              <li>Ante cualquier otra duda, también puedes escribirnos a <strong> info@welivic.com</strong>.</li>
            </ol>
              
              
      <p ><strong >Mucha suerte!</strong></p>
    </div>
  );
};

export default StepStatic3;