import React from 'react';

const StepOneStatic = () => {
  return (
    <div>
      <p>Ve a la sección de <strong>"Cursos"</strong>, busca el curso que desees llevar con nosotros y presiona en "inscribirme".</p>
      <p>Podras ver todo el contenido del curso, la metodología que seguiras, conocerás al profesor que te enseñará y podrás ver un video para conocer mas del curso</p>
      <p>Finalmente incríbete, presiona nuevamente en "Inscribirme" y llena el fomulario para enviarnos tu ficha de matrícula.</p>
      <p>Recuerda que para matricularte debes <strong>iniciar sesión o registrarte en WeLivic</strong> si aun no lo has hecho.</p>
      <p>Te llegará un correo indicándote los medios de pago, una vez realizado el pago, el curso se habilitará en tu cuenta y podras comenzar a aprender!</p>
    </div>
  );
};

export default StepOneStatic;