import React, { useRef, useState } from "react"
import {Form, Button, Card, Alert, Container, Row, Col} from "react-bootstrap"
import { useAuth } from "../context/AuthContext"
import { Link, useHistory } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import Divider from "@material-ui/core/Divider";
// import Logo from "../assets/static/logoFeddVP.png";
import '../assets/styles/pages/Signup.scss'
import SignupImg from '../assets/static/signup-min.jpg'
// import FooterComponent from "../components/shared/FooterComponent";

import { Icon,} from '@iconify/react';
import googleIcon from '@iconify/icons-flat-color-icons/google';

export default function Signup() {
  // useRef es como utilizar useState, sin embargo, use ref es mutable directamente y puede ser asignado
  //al formulario para capturar su valor y en este caso hacer las validaciones correspondientes
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup, loginGoogle } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  
  async function handleSubmit(e) {
    e.preventDefault()

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords no son iguales")
    }

    try {
      setError("")
      setLoading(true)
      await signup(emailRef.current.value, passwordRef.current.value)
      history.push("/mycourses")
    } catch {
      setError("Ocurrió un error al crear la cuenta")
    }

    setLoading(false)
  }

  async function loginWithGoogle(e){
    e.preventDefault();
    try {
      setError("")
      setLoading(true)
      await loginGoogle()
      history.push("/mycourses")
    } catch {
      setError("Failed to log in")
    }
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 765 })
    return isMobile ? children : null
  }
  return (
    <>
      {/* <div className= "navbar-container pt-3 pb-3 fixed-top">
        <Link to="/">
          <img className='navbar__image' src={Logo} alt='feedVPLogo'/>
        </Link>
        <div>
          <Desktop>
            ¿Ya tienes cuenta?<Link  to="/login" className="signUp-button">Log In</Link>
          </Desktop>
          <Mobile>
            <Link  to="/login" className="signUp-button">Log In</Link>
          </Mobile>
        </div>
      </div> */}
      <div className='signup-container'>

        <Container>
          <Row>
            <Desktop>
                <Col md='6'>
                  <img className="w-100" alt="login" src={SignupImg}/>
                </Col>
              </Desktop>
            <Col md='6'>
              <Card className='signup-card-container'>
                <Card.Body>
                  <h3 className=" mb-4">Crea una cuenta</h3>
                  {error && <Alert variant="danger">{error}</Alert>}
                  <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" ref={emailRef} required  className='common-form' placeholder="Ingresa tu Correo Electrónico"/>
                    </Form.Group>
                    <Form.Group id="password">
                      <Form.Label>Contraseña</Form.Label>
                      <Form.Control type="password" ref={passwordRef} required className='common-form' placeholder="Ingresa un Password"/>
                    </Form.Group>
                    <Form.Group id="password-confirm">
                      <Form.Label>Confirma tu contraseña</Form.Label>
                      <Form.Control type="password" ref={passwordConfirmRef} required  className='common-form' placeholder="Ingresa un Password"/>
                    </Form.Group>
                    <Button disabled={loading} className="w-100 common-button mt-4 pt-3 pb-3" type="submit">
                      <strong>Registrarme</strong>
                    </Button>
                    <div className='pt-4 pb-4'>
                      <Desktop>
                        <Row>
                          <Col md='5' className='d-flex flex-column justify-content-center'><Divider /></Col>
                          <Col md='2'>
                            <h5 className='sc-fzomME hhnKZs'>
                              <span>o</span>
                            </h5></Col>
                          <Col md='5' className='d-flex flex-column justify-content-center'><Divider/></Col>
                        </Row>
                      </Desktop>
                      <Mobile>
                        <Row>
                          <Col  className='d-flex flex-column justify-content-center'><Divider /></Col>
                          <Col >
                            <h5 className='sc-fzomME hhnKZs'>
                              <span>o</span>
                            </h5></Col>
                          <Col  className='d-flex flex-column justify-content-center'><Divider/></Col>
                        </Row>
                      </Mobile>
                    </div>
                    <button disabled={loading} onClick={loginWithGoogle} className="w-100 btn btn-outline-danger common-button-google pt-2 pb-2" >
                    <Icon icon={googleIcon} style={{fontSize: '38px'}} /><strong> Registrarme con Google</strong>
                    </button>
                  </Form>
                  <div className="w-100  mt-3">
                    Al crear tu cuenta estas aceptando los<Link to="/privacy" ><p className='signup-terms'>Terminos y condiciones</p></Link>
                  </div>
                  <div className="mt-4 text-center">
                    ¿Ya tienes cuenta?<Link  to="/login" className="signUp-button"> <strong>Iniciar Sesión</strong></Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md='0'> </Col>
          </Row>

        </Container>
      </div>
      {/* <FooterComponent/> */}
    </>
  )
}