import firebase from "firebase/app"
import "firebase/auth"
import 'firebase/firestore'

let firebaseConfig = {
  apiKey: "AIzaSyBgnOx06B3sD_wibRt493fFUbEhIgSGqeY",
  authDomain: "welivic.firebaseapp.com",
  projectId: "welivic",
  storageBucket: "welivic.appspot.com",
  messagingSenderId: "293554903357",
  appId: "1:293554903357:web:eb89392b197c363f238d54",
  measurementId: "G-MQLZGHZDLS"
};

const app = firebase.initializeApp(firebaseConfig)

const googleAuthProvider = new firebase.auth.GoogleAuthProvider()

export const auth = app.auth()
export const db = app.firestore()
export const googleAuth = googleAuthProvider
export default app