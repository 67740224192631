import React,{useState} from 'react';
import { Container } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import "../assets/styles/containers/HowStaticSec.scss"
import StepOneStatic from '../components/StepOneStatic';
import StepStatic2 from '../components/StepStatic2';
import StepStatic3 from '../components/StepStatic3';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 20,
  },
}));
function getSteps() {
  return ['Inscríbete', 'Aprende', 'Certifícate'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <StepOneStatic></StepOneStatic>;
    case 1:
      return <StepStatic2></StepStatic2>;
    case 2:
      return <StepStatic3></StepStatic3>;
    default:
      return 'Unknown step';
  }
}
const HowStaticSec = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>
      <Container className="steps-container">
      <h1 className="mt-5 mb-5 pt-5 text-center"><strong>¿Cómo funciona?</strong></h1>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel className="step-label">{label}</StepLabel>
                  <StepContent>
                  <div className="step">
                    {getStepContent(index)}
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Volver
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1 ? 'Listo!' : 'Siguiente'}
                        </Button>
                      </div>
                    </div>
                  </div>
                  </StepContent>
                </Step>

            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} className={classes.resetContainer}>
              <Typography>Todo claro?</Typography>
              <Button onClick={handleReset} className={classes.button}>
                Otra vez
              </Button>
            </Paper>
          )}
        </div>
      </Container>
    </div>
  );
  
  
};

export default HowStaticSec;