import React, { Fragment, useState } from 'react';
import {Navbar,Nav, Container, Dropdown, Alert} from 'react-bootstrap'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Logo from '../../assets/static/longLogoBlack.png'
import '../../assets/styles/components/shared/NavBarComponent.scss'
import { useAuth } from '../../context/AuthContext';

const NavBarComponent = () => {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()
  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }
  return (
    <div>
      <Navbar bg="light" expand="lg" className="navbar-container">
        <Container>
            {/* <Navbar.Brand href="#home"> */}
            <Link to="/" className='navbar-brand'><img alt="logo" src={Logo}  height="70" className="d-inline-block align-top pr-4"></img></Link>
            {/* </Navbar.Brand> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                {/* <Nav.Link href="#home"> */}
                  <Link className='nav-link d-flex align-items-center' to="/">Inicio</Link>
                  <Link className='nav-link  d-flex align-items-center' to="/cursos">Cursos</Link>
                  <Link className='nav-link  d-flex align-items-center' to="/seminarios">Seminarios</Link>
                  <Link className='nav-link  d-flex align-items-center' to="/contacto">Contáctanos</Link>
                {/* </Nav.Link> */}
                {/* <Nav.Link href="#link"><Link className='nav-link' to="/cursos">Cursos</Link></Nav.Link>           
                <Nav.Link href="#link"><Link className='nav-link' to="/seminarios">Seminarios</Link></Nav.Link>   
                <Nav.Link href="#link"><Link className='nav-link' to="/contacto">Contáctanos</Link></Nav.Link>             */}
              </Nav>
              {
                currentUser ?
                <Fragment>
                  <Dropdown className='pr-3 text-right' alignRight={true}>
                  {error && <Alert variant="danger">{error}</Alert>}
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      Hola <strong>{currentUser.email}</strong>
                    </Dropdown.Toggle>
                    <div className='text-right'>
                      <Dropdown.Menu >
                        {/* <Dropdown.Item className='mt-2 mb-2' > */}
                          <Link  to="/profile" className='mt-2 mb-2 dropdown-item'>Mi Perfil</Link>
                          
                        {/* </Dropdown.Item> */}
                        {/* <Dropdown.Item className='mt-2 mb-2' > */}
                          <Link   to="/mycourses"className='mt-2 mb-2 dropdown-item'>Mis Cursos</Link>
                          
                        {/* </Dropdown.Item> */}
                        {/* <Dropdown.Item className='mt-2 mb-2' >
                          <Link   to="/">Mis Certificados</Link>
                          
                        </Dropdown.Item> */}
                        <Dropdown.Item onClick={handleLogout} >
                          {/*<Button variant="link" onClick={}>*/}
                          <i className="fas fa-sign-out-alt"/> Cerrar Sesión
                        {/*</Button>*/}
                        </Dropdown.Item>
                      </Dropdown.Menu>

                    </div>
                  </Dropdown> 

                </Fragment>
                : <Link   to="/login" className="btn btn-primary">Iniciar sesión</Link>     
              }
            </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBarComponent;