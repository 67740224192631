import React, { useEffect, useRef, useState } from "react"
import {Form, Button, Card, Alert, Container, Spinner, Row, Col, Toast} from "react-bootstrap"
import { useAuth } from "../context/AuthContext"
// import { Link, useHistory } from "react-router-dom"
import { Link} from "react-router-dom"

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { db } from "../config/Firebase.config"
import Skeleton from "react-loading-skeleton";
import "../assets/styles/pages/UpdateProfile.scss"

export default function UpdateProfile() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  // const history = useHistory()
  const [profile,setProfile] = useState("")
  const [idDoc, setIdDoc] = useState("")
  const [disableBtnCreate, setDisableBtnCreate] = useState(false)
  const nameRef = useRef()
  const surenameRef = useRef()
  const lastnameRef = useRef()
  const seclastnameRef = useRef()
  const ageRef = useRef()
  const phoneRef = useRef()
  const dniRef = useRef()


  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  // const toggleShowA = () => setShowA(!showA);

  useEffect(() =>{
    db.collection('users').where("uid", "==",currentUser.uid).onSnapshot((querySnapshot)=>{
      let docProfile = "no-profile"
      querySnapshot.forEach(doc => {
        docProfile = doc.data()
        setIdDoc(doc.id)
        // console.log(doc.data().email + " este");
      })
      setProfile(docProfile);
    })
  },[currentUser.uid])
  
  
  // const getProfile = async () =>{
  //   db.collection('users').where("email", "==",currentUser.email).onSnapshot((querySnapshot)=>{
  //     let docProfile = "no-profile"
  //     querySnapshot.forEach(doc => {
  //       docProfile = doc.data()
  //       setIdDoc(doc.id)
  //       // console.log(doc.data().email + " este");
  //     })
  //     setProfile(docProfile);
  //   })
  //   // console.log(querySnapshot);
  // }

  const createNewProfile = async () => {
    setDisableBtnCreate(true)
    setTimeout(async () => {
      await db.collection('users').doc().set({
        "uid":currentUser.uid,
        "email":currentUser.email,
        "name": "",
        "surename" : "",
        "lastname": "",
        "seclastname": "",
        "age":"",
        "phone": "",
        "dni" : ""
      })
      
    }, 2000);
  }
  async function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    const promises = []
    setLoading(true)
    setError("")

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value))
      if(profile !== "no-profile"){
        await db.collection('users').doc(idDoc).update({
          "email":emailRef.current.value,
        })
      }
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value))
    }

    Promise.all(promises)
      .then(() => {
        
        setShowA(true)
        setTimeout(() => {
          setShowA(false)
          setLoading(false)
        }, 5000);
        // history.push("/profile")
      })
      .catch(() => {
        setError("Failed to update account")
      })
      .finally(() => {
        setLoading(false)
      })
  }
  
  async function handleSubmitProfile(e) {
    e.preventDefault()
    setLoading(true)
    setError("")
    await db.collection('users').doc(idDoc).update({
      "email":emailRef.current.value,
      "name": nameRef.current.value,
      "surename" : surenameRef.current.value,
      "lastname": lastnameRef.current.value,
      "seclastname": seclastnameRef.current.value,
      "age":ageRef.current.value,
      "phone": phoneRef.current.value,
      "dni" : dniRef.current.value
    }).then(()=>{
      
      setShowB(true)
      setTimeout(() => {
        setShowB(false)
        setLoading(false)
      }, 5000);
    }).catch(()=>{
      setError("Failed to update account")
    })
    
  }

  return (
    <>
      <div className="fixed-bottom mb-5 d-flex justify-content-end">
        <Toast show={showA} className=" mt-4 mr-5">
            <Toast.Header>
              <img
                src="https://img.icons8.com/color/2x/idea.png"
                className="rounded mr-2"
                alt=""
                width={20}
                height={20}
              />
              <strong className="mr-auto">Actualización de cuenta</strong>
              <small>Hace un instante</small>
            </Toast.Header>
            <Toast.Body>Haz actualizado los datos de tu cuenta</Toast.Body>
        </Toast>
        <Toast show={showB} className=" mt-4 mr-5">
            <Toast.Header>
              <img
                src="https://img.icons8.com/color/2x/idea.png"
                className="rounded mr-2"
                width={20}
                height={20}
                alt=""
              />
              <strong className="mr-auto">Perfil Actualizado</strong>
              <small>Hace un instante</small>
            </Toast.Header>
            <Toast.Body>Se han guardado tus datos</Toast.Body>
        </Toast>

      </div>
      <Container className="pt-5 pb-5 mb-5">
        <div className="w-100 text-left mb-3">
          <Link to="/profile" className='btn btn-outline-info'><ArrowBackIosIcon /> Volver a mi Perfil</Link>
        </div>
        <Row>
          <Col md={6} className="mt-3">
            
            <Card>
              <Card.Body>
                <h2 className="text-center mb-4">Actualizar Datos de tu Cuenta</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Alert variant="info">Importante! Si cambias tu correo electrónico y no ves tus cursos incritos. Contacta con <strong>team@welivic.com </strong> para solucionar tu problema.</Alert>
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      ref={emailRef}
                      required
                      defaultValue={currentUser.email}
                      className='common-form'
                    />
                  </Form.Group>
                  <Form.Group id="password">
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control
                          type="password"
                          ref={passwordRef}
                          placeholder="Deja en blanco para mantener la misma contraseña"
                          className='common-form'
                        />
                      </Form.Group>
                      <Form.Group id="password-confirm">
                        <Form.Label>Confirmación de contraseña</Form.Label>
                        <Form.Control
                          type="password"
                          ref={passwordConfirmRef}
                          placeholder="Deja en blanco para mantener la misma contraseña"
                          className='common-form'
                        />
                      </Form.Group>
                  <Button disabled={loading} className="w-100 common-button pt-2 pb-2" type="submit">
                    Actualizar datos de mi cuenta
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="mt-3">

          {
            profile !== "" ? 
            <>
            {
              profile !== "no-profile" ? 
              <Card >
                <Card.Body>
                  <h2 className="text-center mb-4">Actualizar Perfil</h2>
                  <Form onSubmit={handleSubmitProfile}>
                    <Form.Group id="name">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        type="text"
                        ref={nameRef}
                        defaultValue={profile.name}
                        className='common-form'
                      />
                    </Form.Group>
                    <Form.Group id="surename">
                      <Form.Label>Segundo Nombre (opcional)</Form.Label>
                      <Form.Control
                        type="text"
                        ref={surenameRef}
                        defaultValue={profile.surename}
                        className='common-form'
                      />
                    </Form.Group>
                    <Form.Group id="lastname">
                      <Form.Label>Apellido Paterno</Form.Label>
                      <Form.Control
                        type="text"
                        ref={lastnameRef}
                        defaultValue={profile.lastname}
                        className='common-form'
                      />
                    </Form.Group>
                    <Form.Group id="seclastname">
                      <Form.Label>Apellido Materno</Form.Label>
                      <Form.Control
                        type="text"
                        ref={seclastnameRef}
                        defaultValue={profile.seclastname}
                        className='common-form'
                      />
                    </Form.Group>
                    <Form.Group id="age">
                      <Form.Label>Edad</Form.Label>
                      <Form.Control
                        type="number"
                        ref={ageRef}
                        defaultValue={profile.age}
                        className='common-form'
                      />
                    </Form.Group>
                    <Form.Group id="phone">
                      <Form.Label>Teléfono</Form.Label>
                      <Form.Control
                        type="text"
                        ref={phoneRef}
                        defaultValue={profile.phone}
                        className='common-form'
                      />
                    </Form.Group>
                    <Form.Group id="dni">
                      <Form.Label>DNI</Form.Label>
                      <Form.Control
                        type="text"
                        ref={dniRef}
                        defaultValue={profile.dni}
                        className='common-form'
                      />
                    </Form.Group>
                    
                    <Button className="w-100 common-button pt-2 pb-2" type="submit" disabled={loading}>
                    {
                      loading === false ?
                      <>
                      Actualizar Perfil
                      </>
                      :
                      <>
                      {
                        showB === false ?
                        <>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          /> Actualizando datos...
                        </>
                        :
                        <>
                        Actualizar Perfil
                        </>
                      }
                      </>
                    }
                      
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
              :
              <>
              <div className="mt-5">
                <Alert variant="info">Parece que aun no has creado tu perfil</Alert>
                <Button onClick={createNewProfile} disabled={disableBtnCreate}>
                {
                      disableBtnCreate === false ?
                      <>
                      Crear perfil
                      </>
                      :
                      <>
                      <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      Creando Perfil...
                      </>
                    }
                </Button>

              </div>
              </>
            }
            </>
            :
            <>
            <Skeleton count={1} height={200}></Skeleton>
            <Skeleton count={5}></Skeleton>
            </>
          }
          
          </Col>
          
        </Row>
      </Container>
    </>
  )
}