import React, { useRef, useState } from "react"
import {Form, Button, Card, Alert, Container, Col, Row} from "react-bootstrap"
import Divider from '@material-ui/core/Divider';
import { useAuth } from "../context/AuthContext"
import { Link, useHistory } from "react-router-dom"
import '../assets/styles/pages/Login.scss'
// import FooterComponent from "../components/shared/FooterComponent";
import { useMediaQuery } from 'react-responsive'
// import Logo from "../assets/static/logoFeddVP.png";
import LoginImg from '../assets/static/login-min.jpg'
import { Icon,} from '@iconify/react';
import googleIcon from '@iconify/icons-flat-color-icons/google';


export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login, loginGoogle } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  // se utiliza para usarlo como redirect y redirecciónar al usuario a una ruta
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      history.push("/mycourses")
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }

  async function loginWithGoogle(e){
    e.preventDefault();
    try {
      setError("")
      setLoading(true)
      await loginGoogle()
      history.push("/mycourses")
    } catch {
      setError("Failed to log in")
    }
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 765 })
    return isMobile ? children : null
  }
  return (
    <>
      {/* <div className= "navbar-container pt-3 pb-3 fixed-top">

        <Link to="/">
          <img className='navbar__image' src={Logo} alt='feedVPLogo'/>
        </Link>
        <div>
          <Desktop>
            ¿No tienes cuenta?<Link  to="/signup" className="signUp-button">Sign Up</Link>
          </Desktop>
          <Mobile>
            <Link  to="/signup" className="signUp-button">Sign Up</Link>
          </Mobile>
        </div>


      </div> */}
      {/*<NavbarComponent/>*/}
      <div className='login-container'>
      <Container >
        <div >
          <Row>
            <Desktop>
              <Col md='6'>
                <img className="w-100" alt="login" src={LoginImg}/>
              </Col>

            </Desktop>
            <Col md='6'><Card className='login-card-container'>
              <Card.Body>
                <h3 className=" mb-4">Ingresa a tu cuenta</h3>
                {error && <Alert variant="danger">{error}</Alert>}                
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" ref={emailRef} required className='common-form' placeholder="Ingresa tu Correo Electrónico" />
                  </Form.Group>
                  <Form.Group id="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" ref={passwordRef} required className='common-form' placeholder="Ingresa tu Password"/>
                  </Form.Group>
                  <div className="w-100 login-forgot-password mt-3">
                  <Link to="/forgot-password" ><p className='login-forgot-password'>¿Olvidaste tu contraseña?</p></Link>
                  </div>
                  <Button disabled={loading} className="w-100 common-button mt-4 pt-3 pb-3" type="submit">
                    <strong>Iniciar Sesión</strong>
                  </Button>
                </Form>
                <div className='pt-4 pb-4'>
                  <Desktop>
                    <Row>
                      <Col md='5' className='d-flex flex-column justify-content-center'><Divider /></Col>
                      <Col md='2'>
                        <h5 className='sc-fzomME hhnKZs'>
                          <span>o</span>
                        </h5></Col>
                      <Col md='5' className='d-flex flex-column justify-content-center'><Divider/></Col>
                    </Row>
                  </Desktop>
                  <Mobile>
                    <Row>
                      <Col  className='d-flex flex-column justify-content-center'><Divider /></Col>
                      <Col >
                        <h5 className='sc-fzomME hhnKZs'>
                          <span>o</span>
                        </h5></Col>
                      <Col  className='d-flex flex-column justify-content-center'><Divider/></Col>
                    </Row>
                  </Mobile>
                </div>
                <button disabled={loading} onClick={loginWithGoogle} className="w-100 btn btn-outline-danger common-button-google pt-2 pb-2" >
                <Icon icon={googleIcon} style={{fontSize: '38px'}} /> <strong>Iniciar Sesión con Google</strong>
                </button>
                <div className="mt-4 text-center">
                  ¿No tienes cuenta?<Link  to="/signup" className="signUp-button"> <strong>Regístrate</strong></Link>

                </div>
              </Card.Body>
            </Card>

            </Col>
            <Col md='0'> </Col>
          </Row>
        </div>
      </Container>
      </div>
      {/* <FooterComponent/> */}

    </>
  )
}