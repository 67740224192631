import React from 'react';
import CourseGrid from '../containers/CourseGrid';

const Courses = () => {
  
  return (
    <div>
      <CourseGrid titleSection="Todos nuestros cursos" query="?_sort=published_at:DESC"></CourseGrid>
      
    </div>
  );
};

export default Courses;