import React, { useEffect, useState } from 'react';
// import {Carousel, Container} from 'react-bootstrap'
import {Carousel} from 'react-bootstrap'
import AdFlyer from '../components/AdFlyer';
import '../assets/styles/containers/CarouselHome.scss'
import Skeleton from 'react-loading-skeleton';

const CarouselHome = (props) => {
  const [data,setData] = useState([])
  const {
    query
  } = props
  const baseUrl = "https://welivic-backend.onrender.com"
  const API = "/courses"+query
  useEffect(()=>{
    fetch((baseUrl + API), {
      method:'GET',
      headers: {'Content-Type': 'application/json'},
    })
      .then(response =>
        response.json()
      
      )
      .then(data => {
        // console.log("esta es la del add");
        // console.log(data);
        setData(data)
      })
  },[API])
  // const itemsGet = [1,2,3]
  return (
    <div >
      <Carousel className="carousel-continer">
      {
        data.length > 0 ?
        data.map((i, key)=>(
          <Carousel.Item key={key}>
            <div>
              <AdFlyer
                // imgProf = {baseUrl + i["teacher"]["picture_profile"]["url"]}
                imgProf = {i["teacher"]["img"]}
                profName = {i["teacher"]['name'] + " " + i["teacher"]['last_name'] }
                // imgAd = {baseUrl + i["portrait_image"]["url"]}
                imgAd = {i["img"]}
                titleAd = {i["title"]}
                methodology = {i["methodoloy"]}
                initDate = {i["start_date"] ? i[ "start_date"] : "Empieza hoy mismo!"}
                duration = {i["duration_hours"]}
                level = {i["level"]}
                comparitionPrice = {i["discount_price"]}
                realPrice = {i["price"]}  
              />
            </div>          
          </Carousel.Item>
        )):
        <div className="pt-5 mt-5">
          <Skeleton height={250}/> 
          <Skeleton count={5}/> 

        </div>
      }
        
      </Carousel>
    </div>
  );
};

export default CarouselHome;