import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Quiz from 'react-quiz-component';
import { quiz } from './quiz';

const QuizTest = () => {
  const [botonContinuar,setBotonContinuar] = useState(false)
  const onCompleteAction = (obj) => {
    console.log(obj);
    console.log(obj.correctPoints);
    setBotonContinuar(true)
    return obj["correctPoints"]
    // YOUR LOGIC GOES HERE
  }
  return (
    <div>
    <Container>
    <Row>
      <Col md={4}>
      <h1>Evaluación final Curso de Analisis y diseño estructural</h1>
      <h3>Puntaje mínimo para aprobar 5 de {quiz["questions"].length}</h3>
      <h4>Cantidad de intentos restantes 1 de 2</h4>
        {
          botonContinuar ?
          <div>
            {/* <p>Nota final {onCompleteAction()} </p> */}
            <button className="btn btn-primary">Continuar</button>

          </div>
          : <div></div>

        }

      </Col>
      <Col md={8}>
          <Quiz quiz={quiz} shuffle={true} onComplete={onCompleteAction}/>

      </Col>
    </Row>

    </Container>
    </div>
  );
};

export default QuizTest;