import React from 'react';

const StepStatic2 = () => {
  return (
    <div>
      <p>Cuando hayas iniciado sesión, te encontrarás en tus cursos y podrás ver todos los cursos en los que te has inscrito junto con el contenido, trabajo y examen de cada curso.</p>
      <p>Para ingresar a ver el contenido del curso presiona en "Ver contenido" y empieza a aprender.</p>
      <p>Para ver el trabajo y/o el examen presiona en el botón que corresponda.</p>
      <p>Algunos cursos bajo la metodología "Sincrona" o "Semi-sincrona" tendrán contacto directo con los profesores por lo que debes mantenerte atento a tu correo o celular, ya que se pondrán en contacto contigo al iniciar el curso.</p>
      <p>No olvides que ante cualquier duda, puedes comunicarte con nosotros en <strong>team@welivic.com</strong></p>
      
    </div>
  );
};

export default StepStatic2;