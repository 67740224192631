import React,{useEffect, useState} from 'react';
import { Container, Row,Col} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import CourseResumeBox from '../components/CourseResumeBox';

const CourseGrid = (props) => {
  const [data,setData] = useState([])
  const {
    titleSection,
    query,
    isHome
  } = props
  const baseUrl = "https://welivic-backend.onrender.com"
  const API = "/courses"+query
  useEffect(()=>{
    fetch((baseUrl + API), {
      method:'GET',
      headers: {'Content-Type': 'application/json'},
    })
      .then(response =>
        response.json()
      
      )
      .then(data => {
        // console.log(data);
        setData(data)
      })
  },[API])
  const spinners = [1,2,3]
  return (
    <div className="mt-0 pt-2">
      <Container>
        <h1 className="text-center mt-3 mb-3 pt-2 pb-3"><strong>{titleSection}</strong></h1>
        <Row>
          {
            data.length > 0 ?
            data.map(((i, key) =>(
              <Col md={4} key={key}>                
                <CourseResumeBox 
                route={i['route']}
                // image = {baseUrl + i["portrait_image"]["url"]}
                image = {i["img"]}
                teacherName = {i["teacher"]['name'] + " " + i["teacher"]['last_name'] }
                // teacherImg = {baseUrl + i["teacher"]["picture_profile"]["url"]}
                teacherImg = {i["teacher"]["img"]}
                title = {i["title"]}
                minDescription = {i["description"]}
                methodology = {i["methodoloy"]}
                initDate = {i["start_date"] ? i[ "start_date"] : "Empieza hoy mismo!"}
                duration = {i["duration_hours"]}
                lvl = {i["level"]}
                comparitionPrice = {i["discount_price"]}
                realPrice = {i["price"]}  
                />
              </Col>
            )))
            :
            spinners.map((i,key) => (
              <Col md={4} key={key}>
                <Skeleton count={15}/>
                {/* <div className="d-flex justify-content-center">
                    <Spinner animation="grow" variant="primary" />
                </div> */}
              </Col>
            ))
          }
        </Row>
        {
          isHome === true ?
          <div className="d-flex justify-content-center mt-5">
            <Link to="/cursos" className="btn btn-primary pt-3 pb-3 mt-5 mb-5 pl-5 pr-5">
              {/* <button  > */}
                Ver mas Cursos

              {/* </button> */}

            </Link>

          </div>:
          <div></div>
        }
      </Container>
    </div>
  );
};

export default CourseGrid;