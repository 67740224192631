import React, { useRef, useState } from "react"
import {Form, Button, Card, Alert, Container, Col, Row} from "react-bootstrap"
import { useAuth } from "../context/AuthContext"
import { Link } from "react-router-dom"
// import Logo from "../assets/static/logoFeddVP.png";
import {useMediaQuery} from "react-responsive";
// import FooterComponent from "../components/shared/FooterComponent";
import '../assets/styles/pages/ForgotPassword.scss'
import ForgotImg from '../assets/static/forgot-min.jpg'

export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage("Revisa tu correo para reestablecer tu contraseña")
    } catch {
      setError("Error al recuperar contraseña")
    }

    setLoading(false)
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
  }
  // const Mobile = ({ children }) => {
  //   const isMobile = useMediaQuery({ maxWidth: 765 })
  //   return isMobile ? children : null
  // }
  return (
    <>
      {/* <div className= "navbar-container pt-3 pb-3 fixed-top">

        <Link to="/">
          <img className='navbar__image' src={Logo} alt='feedVPLogo'/>
        </Link>
        <div>
          <Desktop>
            ¿No tienes cuenta?<Link  to="/signup" className="signUp-button">Sign Up</Link>
          </Desktop>
          <Mobile>
            <Link  to="/signup" className="signUp-button">Sign Up</Link>
          </Mobile>
        </div>
      </div> */}
      <div className='forgot-container'>
        <Container>
          <Row>
              <Desktop>
                <Col md='6'>
                  <img className="w-100" alt="login" src={ForgotImg}/>
                </Col>
              </Desktop>
            <Col md='6'>
              <Card className='forgot-card-container'>
                <Card.Body>
                  <h3 className="mb-4">¿Olvidaste tu contraseña?</h3>
                  {error && <Alert variant="danger">{error}</Alert>}
                  {message && <Alert variant="success">{message}</Alert>}
                  <p>Ingresa el correo electrónico asociado a tu cuenta para enviarte un link y reestablecer tu contraseña</p>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" ref={emailRef} required  className='common-form' placeholder="Ingresa tu Correo Electrónico"/>
                    </Form.Group>
                    <Button disabled={loading} className="w-100 common-button mt-4 pt-2 pb-2" type="submit" >
                      Reestablecer contraseña
                    </Button>
                  </Form>
                  <div className="w-100 text-center mt-3">
                    <Link to="/login"><p className='forgot-loginlink'>Login</p></Link>
                  </div>
                </Card.Body>
              </Card>

            </Col>
            <Col md='0'> </Col>
          </Row>

        </Container>
      </div>
      {/* <FooterComponent/> */}
    </>
  )
}