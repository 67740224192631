import { Avatar } from '@material-ui/core';
import React from 'react';
// import ReactPlayer from 'react-player/youtube';
import { useMediaQuery } from 'react-responsive'
import { makeStyles } from '@material-ui/core/styles';
import { Alert, Col, Row } from 'react-bootstrap';
import Quiz from 'react-quiz-component';
import Chip from '@material-ui/core/Chip';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import '../assets/styles/containers/Lessons.scss'
import ReactMarkdown from 'react-markdown';
// import Fade from 'react-reveal/Fade';

// import Fab from '@material-ui/core/Fab';

// import PublishIcon from '@material-ui/icons/Publish';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const Lessons = (props) => {
  const {
    chapters,
    course
  } = props
  const classes = useStyles();
  // const [saveMessage, setSaveMessage] = useState("Guardar progreso")

  // const baseUrl = "https://welivic-backend.onrender.com"
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 765 })
    return isMobile ? children : null
  }
  // const [botonContinuar,setBotonContinuar] = useState(false)
  const onCompleteAction = (obj) => {
    // console.log(obj);
    // console.log(obj.correctPoints);
    // setBotonContinuar(true)
    return obj["correctPoints"]
    // YOUR LOGIC GOES HERE
  }
  return (
    <div>
      {
        chapters.map((i,key)=>(
            <div key={key}>
            
            {/* <Fade bottom key={key} > */}
            <div className="mb-4" style={{"height":"20px"}} id={"chapter"+ key}> </div>
            <div className="mt-5 mb-5 pt-3">
              <Alert variant="info" ><h1 className="text-center ">Capítulo {key+1}: {i['title']}</h1></Alert>

            </div>
            
            
                {
                i["lessons"].map((index,keyL) => (
                  <div key={key+keyL}>
                  
                  <div style={{"height":"10px"}} id={"lesson"+ key  + "."+ keyL} > </div>
                  <div className="mt-5 mb-5 pt-5 pb-5" >
                    <Alert variant="primary"><h5>{"Lección" +(key+1) + "." +(keyL + 1) + ": " + index['title']}</h5></Alert>                     
                    <div className="d-flex justify-content-center mt-5 mb-5">
                    {
                      index['type'] === "video" ?
                        <>
                        <Mobile>
                          <iframe width="100%" height="300px" title={key+keyL}
                            src={index["video"]} >
                          </iframe>
                          {/* <ReactPlayer 
                            controls = "true"
                            width='100%'
                            height="300px"
                            url={index["video"]} 
                            /> */}
                        </Mobile>
                        <Desktop>
                        <iframe width="70%" height="450px" title={key+keyL}
                            src={index["video"]} >
                          </iframe>
                          {/* <ReactPlayer 
                            controls = "true"
                            width='75%'
                            height="450px"
                            url={index["video"]} 
                            /> */}
                        </Desktop>
                        </> :
                        index['type'] === "quiz" ?
                        <>
                        <Row className="d-flex justify-content-center">
                          <Col md={12}>
                              <Quiz quiz={index["exam"]} shuffle={true} onComplete={onCompleteAction}/>

                          </Col>
                        </Row>
                        </>
                        : index['type'] === "lecture" ?
                        <>
                        {
                          index["article"].length > 0 ?
                          <ReactMarkdown className="mt-5 mb-5 text-justify" source={index["article"]} />
                          : <div></div>
                        }
                        </>
                        :
                        <></>

                    }
                    </div>
                    <div className="lesson-footer mt-2 mb-4">
                      <div className="d-flex align-items-center mt-2 mb-2">
                        {/* <Avatar alt="Remy Sharp" src={baseUrl + course["teacher"]["picture_profile"]["url"]} className={classes.large} /> */}
                        <Avatar alt="Remy Sharp" src={course["teacher"]["img"]} className={classes.large} />
                        <div style={{'width': '10px'}}></div>
                        <h6>{"Profesor(a) :" + course["teacher"]["name"] + " "+ course["teacher"]["last_name"]}</h6>   
                        <div style={{'width': '20px'}}></div>   

                      </div>
                      {
                        index['material'] ?
                        <a href={index['material']} target="_blank" rel="noreferrer" className="mt-2 mb-2">
                          <Chip
                            icon={<BookmarkIcon />}
                            label="Material de la clase"
                            clickable
                            color="primary"
                            // onDelete={handleDelete}
                            // deleteIcon={<DoneIcon />}
                          />
                        </a>  :
                        <></>              
                      }
                      {/* <div className="d-flex align-items-center mt-2 mb-2">
                        Guardar progreso
                        <div style={{'width': '20px'}}></div>   
                        <Fab color="primary" aria-label="add" >
                          <PublishIcon />
                        </Fab>

                      </div>  */}
                    </div>
                    
                  </div>
                  </div>  
                ))
              }
              {/* </Fade> */}
            </div>
          ))
      }
      
    </div>
  );
};

export default Lessons;