import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../assets/styles/containers/WhatStaticSec.scss'
import Logo from "../assets/static/Logoblanco.png"
import { Link } from 'react-router-dom';

const WhatStaticSec = () => {
  return (
    <div className="what-static-continer p-5 mt-5 mb-5">
      <Container className="mt-5 mb-5">
        <Row>
          <Col md={6}>
            <h1 className="mt-5 mb-5"><strong>¿Qué es WeLivic?</strong></h1>
            <p className="mt-5 mb-5">WeLivic es una plataforma de cursos online para ingenieros que te ayudará a convertirte en el profesional que deseas ser. Te brindamos los mejores cursos de ingeniería con las mejores metodológias para que aprendas a tu ritmo.</p>
            <Link to="/cursos" className="btn btn-primary pr-5 pl-5 pt-2 pb-2">Ver Cursos</Link>
          </Col>
          <Col md={6}>
            <img src={Logo} alt="logo" className="w-100 mt-5 mb-5"></img>
          </Col>
        </Row>        
      </Container>
    </div>
  );
};

export default WhatStaticSec;