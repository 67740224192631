import React, { useEffect, useRef, useState } from 'react';
import { Alert, Col, Container, Overlay, Row, Popover} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import MyCourse from '../components/MyCourse';
import { useAuth } from '../context/AuthContext';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import '../assets/styles/pages/MyCourse.private.scss'

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { currentUser} = useAuth()
  const [myCourses,setMyCourses] = useState()
  const baseUrl = "https://welivic-backend.onrender.com"
  const API = "/course-signeds?uid=" + currentUser.uid
  // console.log(API);
  useEffect(()=>{
    fetch((baseUrl + API), {
      method:'GET',
      headers: {'Content-Type': 'application/json'},
    })
      .then(response =>
        response.json()
      
      )
      .then(data => {
        // console.log(data);
        setMyCourses(data)
        
      })
  },[API])
  const coursesTemp = [1,2,3]

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  return (
    <div className='myCourses-div'>
    <Container className="mt-5 mb-5">
    <Row className="mb-4">
      <Col md={4} className="d-flex align-items-center">
        <h1 className="mb-2 mt-2"><strong>Mis cursos</strong></h1>        
      </Col>
      <Col md={4} className="d-flex align-items-center justify-content-center">
        <Link to="/cursos"><button className="btn btn-primary pr-5 pl-5 pt-3 pb-3 mb-2 mt-2"><ChromeReaderModeIcon></ChromeReaderModeIcon> <strong>Tomar mas Cursos</strong></button></Link>

      </Col>
      <Col md={4} className="d-flex align-items-center justify-content-center">
        <button onClick={handleClick} className="btn btn-info pr-5 pl-5 pt-3 pb-3 mb-2 mt-2"><AssignmentIndIcon></AssignmentIndIcon> ¿Como recibir mi certificado?</button>
        <Overlay
          show={show}
          target={target}
          placement="bottom"
          container={ref.current}
          containerPadding={20}
        >
          <Popover id="popover-contained">
            <Popover.Title as="h3" >Indicaciones para certificarte en <strong>WeLivic</strong></Popover.Title>
            <Popover.Content>
            <ol>
              <li>Aprende con el contenido de tus cursos</li>            
              <li>Entrega tu trabajo final en <strong>Google Classroom</strong></li>
              <li>Realiza la evaluación final con nota <strong>aprobatoria</strong></li>
              <li>Podras ver tus resultados en el resumen del <strong>curso</strong></li>
              <li>Obtendremos tus resultados y <strong> enviaremos tu certificado a tu correo</strong></li>
              <li>Si haz culminado el curso satisfactoriamente y no has recibido tu certificado en los proximos 7 días puedes escribirnos a <strong> team@welivic.com</strong></li>
              <li>Ante cualquier otra duda también puedes escribirnos a <strong> info@welivic.com</strong></li>
            </ol>
              
              
            <p className="text-right"><strong >Mucha suerte!</strong></p>
            </Popover.Content>
          </Popover>
        </Overlay>
      </Col>
    </Row>
      <Row className="mb-5 pb-5">
        {
          myCourses === undefined ?
          coursesTemp.map((i, key)=>(
            <div key={key}>
              <Col md={4}>
                <Skeleton height={150} count={1}/>
                    </Col>
                <Col md={8}>
                <Skeleton count={7}/>
              </Col>
            </div>
              ))
          :
          myCourses.length > 0 ?
          myCourses.map((i,key)=>(
            <Col md={12} key={key}>
              <MyCourse
              disable={i["disable"]}
              id_course={i["id_course"]}
              // progress={i["progress"]}
              finalGrade={i["final_grade"]}
              workGrade = {i["work_grade"]}
              examGrade = {i["exam_grade"]}
              temps={i['temps']}
              ></MyCourse>

          </Col>
          ))
          :
          <div style={{"height":"410px"}} className="mt-5">
            <Alert variant="info"><h1>Aun no estas inscrito en ningun curso</h1></Alert>
          </div>
          
          
        }
      </Row>
      
      <div ref={ref}>
      
    </div>
    </Container>
    </div>
  );
};

export default Dashboard;