
import '../assets/styles/App.scss';
// import RichtText from '../tests/RichtText';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
// import Child from '../tests/Child';
// import items from '../tests/items';
import Layout from '../components/Layout';
import Home from '../pages/Home';
import SubscribedView from '../pages/Subscribed';
import ContactUs from '../pages/ContactUs';
import Courses from '../pages/Courses';
import Seminars from '../pages/Seminars';
import CourseView from '../pages/CourseView';
import SuscriptionForm from '../pages/SuscriptionForm';
import Error404 from '../pages/Error404';
import QuizTest from '../tests/QuizTest';
import { AuthProvider } from '../context/AuthContext';
import PrivateRoute from '../pages/PrivateRoute';
import Dashboard from '../pages/MyCourses.private';
import Signup from '../pages/SignUp';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ProfileComponent from '../pages/Profile';
import UpdateProfile from '../pages/UpdateProfile';
import CourseLessons from '../pages/CourseLessons.private';
import HomeWork from '../pages/HomeWork.private';
import Test from '../pages/Test.private.';
import Maintaince from '../pages/Maintaince';

function App() {
  const maintaince = true;
  return (
    <>
    {
      maintaince ? 
      <Router>
        <AuthProvider>
          <Layout>
            <Switch>
              <Route exact path="/">{Home}</Route>            
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/subscribed" component={SubscribedView}></Route>
              <Route exact path="/contacto" component={ContactUs}></Route>
              <Route exact path="/cursos" component={Courses}></Route>
              <Route path="/seminarios" component={Seminars}></Route>
              <Route path="/quiz" component={QuizTest}></Route>
              <PrivateRoute exact path="/mycourses" component={Dashboard} />
              <PrivateRoute exact path="/profile" component={ProfileComponent}></PrivateRoute>
              <PrivateRoute exact path="/profile/update" component={UpdateProfile}></PrivateRoute>
              {/* <Route path="/:id" children={<Child />} /> */}
              <Route path="/cursos/:id" children={<CourseView />} />
              <PrivateRoute path="/suscribir/:route" component={SuscriptionForm} 
              // children={<SuscriptionForm/>} 

              />
              <PrivateRoute exact path="/mycourses/:course_route" component={CourseLessons} />
              <PrivateRoute exact path="/mycourses/:course_route/work" component={HomeWork} />
              <PrivateRoute exact path="/mycourses/:course_route/test" component={Test} />
              <Route path="*">
                <Error404 />
              </Route>
            </Switch>
          </Layout>        
        </AuthProvider>
      </Router>
      :
      <Maintaince />
    }
    </>
    
  );
}

export default App;
