import React from 'react';
import '../assets/styles/components/CourseResumeBox.scss'

import { Icon} from '@iconify/react';
import bookOutlined from '@iconify/icons-ant-design/book-outlined';
import bxCalendar from '@iconify/icons-bx/bx-calendar';
import clockCircleOutlined from '@iconify/icons-ant-design/clock-circle-outlined';
import barChart from '@iconify/icons-bi/bar-chart';
import { Row,Col } from 'react-bootstrap';
import {Link} from 'react-router-dom'

const CourseResumeBox = (props) => {

  const { 
    route,
    image,
    teacherName,
    teacherImg,
    title,
    minDescription,
    methodology,
    initDate,
    duration,
    lvl,
    comparitionPrice,
    realPrice,

  } = props; 


  const imgUrl = image ? image :"https://www.mvrdv.nl/media/uploads/22_websize_Tianjin_Library_%C2%A9Ossip_1(5).jpg?width=1920"

  const imageBackGround = `linear-gradient(180deg, rgba(255, 255, 255, 0) -59.9%, #001631 100%), url('${imgUrl}'), #737395`

  const imageStyle = {
    background: imageBackGround,
    width: "100%",
    height: "250px",
    backgroundSize: "cover",
    color: "white",
  }


  return (
    <div>
        <div className="course-resume-image-container" style={imageStyle}>
        <Row className="p-4">
          <Col md={8}>
            <div className="course-resume-image-profesor-continer mb-3 text-center">
              <img alt="foto-profesor" src={teacherImg} className="course-resume-image-profesor fluid "></img>
            </div>  
            <div className='ad-teacher-text'>
              <p className='m-0'><strong>Profesor(a):</strong></p>
              <p className='m-0'>{teacherName}</p>              
            </div>            
          </Col>
          <Col md={4} className="text-center">
            <div className="course-resume-new p-1 text-center">
              <p><strong>Nuevo</strong></p>
            </div>
          </Col>  
        </Row>
        </div>
        <div className="course-resume-container p-3 m-2 pt-1 mb-4">
          <h5 className='pt-2 pb-2 course-resume-link'><strong>{title}</strong></h5>
          <p >{minDescription}</p>          
          <p><Icon icon={bookOutlined} style={{color: '#0075ff', fontSize: '17px'}} /> Metodología: {methodology}</p>
          <p><Icon icon={bxCalendar} style={{color: '#0075ff', fontSize: '19px'}} /> Fecha de inicio: {initDate}</p>
          <p><Icon icon={clockCircleOutlined} style={{color: '#0075ff', fontSize: '19px'}} /> Duración: {duration} hrs</p>
          <p><Icon icon={barChart} style={{color: '#0075ff', fontSize: '19px'}} /> Nivel: {lvl}</p>
          <div className="ad-price pt-3"> 
            <h4 className="pr-2 d-flex align-items-center pl-2 ad-comparition-price"><strike><strong>S/. {comparitionPrice}</strong></strike></h4>
            <h2 className="pl-2 ad-real-price"><strong>S/. {realPrice}</strong></h2>
          </div>
          <Link to={"/cursos"+ route} className="btn btn-primary btn-block pt-3 pb-3 mt-4 mb-2"><strong>Inscribirme</strong></Link>          
        </div>
    </div>
  );
};

export default CourseResumeBox;