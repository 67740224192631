import React from 'react';
import WhatStaticSec from '../containers/WhatStaticSec';
import CarouselHome from '../containers/CarouselHome';
import CourseGrid from '../containers/CourseGrid';
import SeminarGrid from '../containers/SeminarGrid';
import WhyStaticSec from '../containers/WhyStaticSec';
import HowStaticSec from '../containers/HowStaticSec';
// import Brands from '../containers/Brands';
// import ReactPlayer from 'react-player'

const Home = () => {
  return (
    <div>
      <CarouselHome query="?_limit=6"></CarouselHome>
      <CourseGrid titleSection="Nuevos cursos" query="?_sort=published_at:DESC&_limit=6" isHome={true}></CourseGrid>
      <WhatStaticSec></WhatStaticSec>
      <SeminarGrid titleSection="Nuevos Seminarios" query="?_sort=published_at:DESC&_limit=6" isHome={true}></SeminarGrid>
      <WhyStaticSec></WhyStaticSec>
      <HowStaticSec></HowStaticSec>
      {/* <Brands></Brands> */}
      {/* <ReactPlayer url='https://www.youtube.com/watch?v=hI5PJkXCKJs' width='100%' /> */}
    </div>
  );
};

export default Home;