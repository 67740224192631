import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "../context/AuthContext"
//  en esta función le pasamos el componente junto con todas sus propuedades
export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()

  return (
    <Route
       // incluimos sus propiedades dentro de la ruta y ke indicamos que renderice
       // dentro de la ruta el componente y sus props si existe currentUser
      {...rest}
      render={props => {
        return currentUser ? <Component {...props} /> : <Redirect to="/login" />
      }}
    >

    </Route>
  )
}
