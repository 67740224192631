import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../assets/styles/containers/WhyStaticSec.scss'
import { Icon} from '@iconify/react';
import chalkboardTeacher from '@iconify/icons-la/chalkboard-teacher';
import certificate20Regular from '@iconify/icons-fluent/certificate-20-regular';
import trophyIcon from '@iconify/icons-uil/trophy';
import bookOutlined from '@iconify/icons-ant-design/book-outlined';
import clockCircleOutlined from '@iconify/icons-ant-design/clock-circle-outlined';

const WhyStaticSec = () => {
  return (
    <div className="why-static-continer p-5 mt-5 mb-5">
      <Container>
      <h1 className="mt-5 mb-5 why-static-title"><strong>¿Por qué elegir WeLivic?</strong></h1>
      <Row>
        <Col md={4} className="d-flex justify-content-center mt-4 mb-4">
          <div className="why-card">
            <div className="why-icon-conatiner">
              <Icon icon={chalkboardTeacher} style={{color: '#001631', fontSize: '81px'}} />
            </div>
            <h4><strong>Profesores Expertos</strong></h4>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia ut sed sed viverra. Turpis mi nisl, sapien, eleifend leo dui</p> */}
          </div>
        </Col>
        <Col md={4} className="d-flex justify-content-center mt-4 mb-4">
          <div className="why-card">
            <div className="why-icon-conatiner">
              <Icon icon={certificate20Regular} style={{color: '#001631', fontSize: '86px'}} />
            </div>
            <h4><strong>Certifícate con nosotros</strong></h4>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia ut sed sed viverra. Turpis mi nisl, sapien, eleifend leo dui</p> */}
          </div>
        </Col>
        <Col md={4} className="d-flex justify-content-center mt-4 mb-4">
          <div className="why-card">
            <div className="why-icon-conatiner">
              <Icon icon={trophyIcon} style={{color: '#001631', fontSize: '72px'}} />
            </div>
            <h4><strong>Mejora tus habilidades</strong></h4>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia ut sed sed viverra. Turpis mi nisl, sapien, eleifend leo dui</p> */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="d-flex justify-content-center mt-4 mb-4">
          <div className="why-card">
            <div className="why-icon-conatiner">
              <Icon icon={bookOutlined} style={{color: '#001631', fontSize: '68px'}} />
            </div>
            <h4><strong>Las mejores Metodologías</strong></h4>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia ut sed sed viverra. Turpis mi nisl, sapien, eleifend leo dui</p> */}
          </div>
        </Col>
        <Col md={6} className="d-flex justify-content-center mt-4 mb-4">
          <div className="why-card">
            <div className="why-icon-conatiner">
              <Icon icon={clockCircleOutlined} style={{color: '#001631', fontSize: '80px'}} />
            </div>
            <h4><strong>Aprende a tu ritmo</strong></h4>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacinia ut sed sed viverra. Turpis mi nisl, sapien, eleifend leo dui</p> */}
          </div>
        </Col>
      </Row>
     </Container>
    </div>
  );
};

export default WhyStaticSec;