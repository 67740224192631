import React, {useState} from 'react';
import { Col, Container, Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import axios from 'axios'

const ContactUs = () => {
  const [contMessage, setContMessage] = useState();
  const [contName, setContName] = useState();
  const [contSubject, setContSubject] = useState();
  const [contEmail, setContEmail] = useState();
  const succesAlert = () =>{
    document.getElementById("contactForm").reset();
    Swal.fire({
        icon: "success",
        title: 'Excelente!',
        text: 'Nos pondremos en contacto lo antes posible para resolver tus dudas',
      // footer: 'Mantente atento a tu correo, te enviaremos una confirmación para la reunión que acabas de agendar'
    })
    }

    // const errorAlert = () => {
    // Swal.fire({
    //     icon:"error",
    //     title:'Oops',
    //     text:'Completa los campos por favor',
    //     footer:'Si el problema persiste probablemente sea tu conexión a internet, intentalo de nuevo mas tarde'
    //     })
    // }

    const loadingAlert = () => {
    Swal.fire({
        imageUrl : 'https://hawkeyestatic.s3.amazonaws.com/images/2cbb5e95b97aa2b496f6eaec84b9240d.gif',
        title:'Cargando...',
        imageWidth: 300,          
        text:'Un minuto por favor',
        showConfirmButton: false
    })
    }


  async function onSubmit (e) {
    e.preventDefault();
    await axios.post('https://welivic-mail-service.herokuapp.com/api/contact',{
      name: contName,
      email: contEmail,
      subject: contSubject,
      message: contMessage
    }).then(function (response) {
      // console.log(response);
      succesAlert()   
    }).catch((error)=>{
      // console.log(error);
    })   
  }
    const showAlert = () =>{   

      function alertEnviado() {
          if ((contName === '') || !(String(contEmail).includes('@')) || contEmail === '' || (contSubject  === '') || (contMessage  === '')) {   
              // errorAlert()       
          }else{          
              loadingAlert()
              
          }
      }
      return(
          alertEnviado()    
      )    
    } 
  return (
    <div>
    <Container className="mt-5 mb-5 pb-5">

      <h3 className='pt-5 pb-3 campaign-title'><strong>Contáctanos</strong></h3>
        <div className='contact-container mt-5'>
          <Form 
          onSubmit={onSubmit} 
          id='contactForm'>
            <Form.Row> 
              <Col md='6'>
                <Form.Group className='pt-0 contact-form-group' controlId="contact-name">
                  <Form.Label className='pb-3 contact-label'>Nombres</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Escribe tu nombre completo" 
                    onChange={(e)=>{setContName(e.target.value)}}
                    name="name"
                    value={contName}
                    required
                    />
                  
                </Form.Group>
                <Form.Group className='pt-2 contact-form-group' controlId="contact-mail">
                  <Form.Label className='pb-3 contact-label'>Email</Form.Label>
                  <Form.Control 
                    type="email" 
                    placeholder="Escribe tu email" 
                    onChange={(e)=>{setContEmail(e.target.value)}}
                    name="email"
                    value={contEmail}
                    required
                    />
                  
                </Form.Group>
                <Form.Group className='pt-2 contact-form-group' controlId="contact-subject">
                  <Form.Label className='pb-3 contact-label'>Asunto</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Escribe un asunto" 
                    onChange={(e)=>{setContSubject(e.target.value)}}
                    name="subject"
                    value={contSubject}
                    required
                    />
                  
                </Form.Group>
              </Col>             
              <Col md='6'>
                <Form.Group  className='pt-0 contact-form-group' controlId="formBasicPassword">
                  <Form.Label className='pb-3 contact-label'>Mensaje</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    placeholder="Escribe tu mensaje" 
                    onChange={(e)=>{setContMessage(e.target.value)}}
                    name="message"
                    value={contMessage}
                    required
                    rows={11}/>
                </Form.Group>
              </Col>
            </Form.Row>
            <div className='contact-button'>
              <button 
                type="submit" 
                className='btn btn-primary btn-block common-button mt-4 pt-3 pb-3 pr-5 pl-5'
                onClick={showAlert}
                >Enviar</button>            
            </div>         
          </Form>
          
        </div>
    </Container>
    </div>
  );
};

export default ContactUs;