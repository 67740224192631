import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ReactMarkdown from 'react-markdown'
import { Alert, Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useAuth } from '../context/AuthContext';

const HomeWork = () => {
  let { course_route } = useParams();
  const [data,setData] = useState('')
  const { currentUser} = useAuth()
  const [courseSigned, setCourseSigned] = useState([])

  const baseUrl = "https://welivic-backend.onrender.com"
  const API = "/courses?route=/"+ course_route
  const API_Fetch = baseUrl + API
  const API_COURSE_SIGNED = baseUrl + "/course-signeds?id_course="
  useEffect(()=>{
    
    fetch((API_Fetch), {
      method:'GET',
      headers: {'Content-Type': 'application/json'},
    })
      .then(response =>
        response.json()      
      )
      .then(data => {
        // console.log(data);
        setData(data)       
        fetch((API_COURSE_SIGNED + data[0]['id'] + "&uid="+currentUser.uid), {
          method:'GET',
          headers: {'Content-Type': 'application/json'},
        })
          .then(response =>
            response.json()      
          )
          .then(data => {
            // console.log(data)
            setCourseSigned(data)
          })
      })

    
  },[API_Fetch,API_COURSE_SIGNED,currentUser.uid])
  return (
    <div>
      <div className='container mb-5 pb-5'>
      <div className="mt-4 mb-5">
        <Link to="/mycourses" className='btn btn-outline-info'><h6 className='m-0'><ArrowBackIosIcon />  Volver a mis cursos</h6></Link>

      </div>
      {
        courseSigned.length > 0?
          <>
          <Row>
            <Col md={8}>
              <Alert className="mt-3 mb-3" variant="primary"><h3>Trabajo Practico {data[0]['title']} </h3></Alert>
              
            </Col>
            <Col md={4}>
            <Alert className="mt-3 mb-3" variant="primary">
              {
                courseSigned[0]['work_grade'] !== "0" ?
                <h3 >Nota final: <strong>{courseSigned[0]['work_grade']} puntos</strong></h3>
                :
                <h3>Sin calificar</h3>
              }
              </Alert>
            </Col>
          </Row>
            <ReactMarkdown className="mt-5 mb-5 pb-5 pt-5 text-justify" source={data[0]['final_work']} />      
          </>
          :
          <>
          <Skeleton height={150}></Skeleton>
          <Skeleton count={10}></Skeleton>
          </>
      }
      </div>
    </div>
  );
};

export default HomeWork;