import React, { useEffect, useState } from 'react';
// import React, {useState} from 'react';
// import {Button, Card, Container} from "react-bootstrap";
import {Card, Col, Container, Row,Alert} from "react-bootstrap";
// import {Link, useHistory} from "react-router-dom";
import {Link} from "react-router-dom";
import {useAuth} from "../context/AuthContext";

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { db } from '../config/Firebase.config';
import Skeleton from "react-loading-skeleton";
// import { Alert } from 'bootstrap';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const ProfileComponent = () => {
  const { currentUser} = useAuth()
  const [profile,setProfile] = useState("")
  const classes = useStyles();

  useEffect(() =>{
    db.collection('users').where("uid", "==",currentUser.uid).onSnapshot((querySnapshot)=>{
      let docProfile = "no-profile"
      querySnapshot.forEach(doc => {
        docProfile = doc.data()
        // console.log(doc.data().email + " este");
      })
      setProfile(docProfile);
    })
    // getProfile()
  },[currentUser.uid])
  
  
  // const getProfile = async () =>{
  //   const querySnapshot = await db.collection('users').where("email", "==",currentUser.email).get()
  //   let docProfile = "no-profile"
  //   querySnapshot.forEach(doc => {
  //     docProfile = doc.data()
  //     // console.log(doc.data().email + " este");
  //   })
  //   // console.log(querySnapshot);
  //   setProfile(docProfile);
  // }
  
  return (
    <div>
      <Container className="mt-5 pt-5 pb-5 mb-5">
        <Card className="mt-5 pt-5 pb-5 mb-5">
          <Card.Body>
            <h2 className="text-center mb-4">Mi Perfil</h2>
            <Row>
              <Col md={4} className="d-flex justify-content-center">
                <Avatar alt="Remy Sharp" src="https://img.icons8.com/dusk/2x/student-center.png" className={classes.large} />
              </Col>
              <Col md={8} className="d-flex align-items-end flex-column justify-content-center">
                <h6><strong>Email:</strong> {currentUser.email}</h6>
              {
                profile !== "" ? 
                <>
                {
                  profile !== "no-profile" ? 
                  <>                  
                    <h6><strong>Nombres:</strong> {profile.name ? profile.name + " " + profile.surename : "Nombres no proporcionados"}</h6>
                    <h6><strong>Apellidos:</strong> {profile.lastname ? profile.lastname  + " " + profile.seclastname : "Apellidos no proporcionados"}</h6>
                    <h6><strong>Edad:</strong> {profile.age ? profile.age : "Edad no proporcionada"}</h6>
                    <h6><strong>Teléfono:</strong> {profile.phone ? profile.phone : "Teléfono no proporcionado"}</h6>
                    <h6><strong>DNI:</strong> {profile.dni ? profile.dni : "DNI no proporcionado"}</h6>                  
                  </>
                  :
                  <>
                  <Alert variant="primary"><h5>Aun no has creado tu perfil</h5></Alert>
                  </>
                }
                </> 
                :
                <Skeleton count={7}/>
              }
              </Col>
            </Row>

            <Link to="/profile/update" className="btn btn-primary w-100 mt-3 pt-3 pb-3 common-button">
              {profile !== "no-profile" ? "Actualizar Perfil" : "Crear perfil o Actualizar Datos"}
            </Link>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default ProfileComponent;
