import React, {Fragment, useEffect, useState} from 'react';
import {Link, useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import { Icon} from '@iconify/react';
import bookOutlined from '@iconify/icons-ant-design/book-outlined';
import bxCalendar from '@iconify/icons-bx/bx-calendar';
import clockCircleOutlined from '@iconify/icons-ant-design/clock-circle-outlined';
import barChart from '@iconify/icons-bi/bar-chart';
import shareAltOutlined from '@iconify/icons-ant-design/share-alt-outlined';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import "../assets/styles/pages/CourseView.scss"
import Skeleton,{ SkeletonTheme } from 'react-loading-skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClassRoom from "../assets/static/classroom-min.png"
import Zoom from "../assets/static/zoom-min.png"
import ReactPlayer from 'react-player'

import {
  FacebookShareButton,
} from "react-share";
import { useAuth } from '../context/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const CourseView = () => {
  const { currentUser} = useAuth()
  const [data,setData] = useState('')
  const [chapters,setChapters] = useState([])
  const [markdown, setMarkdown] = useState('')
  const classes = useStyles();
  let { id } = useParams();
  const baseUrl = "https://welivic-backend.onrender.com"
  const API = "/courses?route=/"+ id
  const API_Fetch = baseUrl + API
  
  useEffect(()=>{
    
    fetch((API_Fetch), {
      method:'GET',
      headers: {'Content-Type': 'application/json'},
    })
      .then(response =>
        response.json()      
      )
      .then(data => {
        // console.log(data[0]['id']);
        setData(data)
        setMarkdown(data[0]["long_description"])
        const urlChapters = "https://welivic-backend.onrender.com/chapters?_sort=order_number:asc&course=" + data[0]['id']
        fetch((urlChapters), {
          method:'GET',
          headers: {'Content-Type': 'application/json'},
        }).then(response =>
          response.json()      
        )
        .then(data => {
          // console.log(data);
          setChapters(data)
        })
        
      })

    
  },[API_Fetch])


  // const imgUrl = data.length > 0 ? baseUrl + data[0]["portrait_image"]["url"] :"https://www.mvrdv.nl/media/uploads/22_websize_Tianjin_Library_%C2%A9Ossip_1(5).jpg?width=1920"
  const imgUrl = data.length > 0 ? data[0]["img"] :"https://www.mvrdv.nl/media/uploads/22_websize_Tianjin_Library_%C2%A9Ossip_1(5).jpg?width=1920"

  const imageBackGround = `linear-gradient(180deg, rgba(255, 255, 255, 0) -59.9%, #001631 100%), url('${imgUrl}'), #737395`

  const imageStyle = {
    background: imageBackGround,
    width: "100%",
    height: "auto",
    backgroundSize: "cover",
    color: "white",
  }

  return (
    <div>
    <Container fluid="xl" className="pt-5">
        {/* <h3>route: {id} holi</h3> */}
        
        <Row>
          <Col md={7}>          
            {
              data.length > 0 ? 
                <div className="course-image-container " style={imageStyle}>
                    <Row className="p-4">
                      <h2 className="p-3"><strong>{data.length > 0 ? data[0]["title"]: <Spinner animation="grow" variant="primary" />}</strong></h2>                     
                        
                        <Col md={4} className="course-teacher-container">
                          <div className="course-image-profesor-continer mb-3 text-center">
                            {/* <img alt="foto-profesor" src={baseUrl + data[0]["teacher"]["picture_profile"]["url"]} className="course-image-profesor fluid "></img> */}
                            <img alt="foto-profesor" src={data[0]["teacher"]["img"]} className="course-image-profesor fluid "></img>
                          </div>  
                          <div className='ad-teacher-text p-3'>
                            <p className='m-0'><strong>Profesor(a):</strong></p>
                            <p className='m-0'>{"Ing. "+data[0]["teacher"]['name'] + " " + data[0]["teacher"]['last_name']}</p>              
                          </div>            
                        </Col>
                        <Col md={8} className="text-center course-facts-container">
                        <div className="text-right p-3 m-2 pt-1 mb-4">             
                          <p>Metodología: {data[0]["methodoloy"]}  <Icon icon={bookOutlined} style={{color: '#FFD600', fontSize: '17px'}} /></p>
                          <p>Fecha de inicio: {data[0][ "start_date"] ? data[0][ "start_date"] : "Empieza hoy mismo!" } <Icon icon={bxCalendar} style={{color: '#FFD600', fontSize: '19px'}} /></p>
                          <p>Duración: {data[0]["duration_hours"]} hrs  <Icon icon={clockCircleOutlined} style={{color: '#FFD600', fontSize: '19px'}} /></p>
                          <p>Nivel: {data[0]["level"]}  <Icon icon={barChart} style={{color: '#FFD600', fontSize: '19px'}} /></p>
                        </div>
                        </Col>  
                    </Row>   
                </div>:
                  /* <Spinner animation="grow" variant="primary" />  */
                  <div style={{ fontSize: 30, lineHeight: 2 }}>
                    <SkeletonTheme color="#1c1c1c1" highlightColor="#444">
                      <Skeleton height={150}/> 
                      <Skeleton count={5}/> 

                    </SkeletonTheme>
                  </div>
            }     
            {
              data.length > 0 ? 
              <Fragment>
                <p className="pt-4 pb-2">{data[0]["description"]}</p>
                {
                  data[0]["methodoloy"] === "asincrono" ?
                  <div className="course-tool p-4 mt-5 mb-4">
                  <Row>
                    <Col md={3} className="text-center">
                      <img alt="zoom-logo" src={ClassRoom} className="w-75 mt-3 mb-3"></img>
                    </Col>
                    <Col md={9} className="text-justify">
                      <h5><strong>Google Classrooms</strong></h5>
                      <p>Utilizaremos una herramienta que ya conoces para que tengas todo el material tanto de videos pre-grabados y grabaciones de calses realizadas asi como para asignarte tus trabajos, ejercicios y exámenes</p>
                    </Col>
                  </Row>
                </div>:
                <Fragment>
                  <div className="course-tool p-4 mt-5 mb-4">
                    <Row>
                      <Col md={3} className="text-center">
                        <img alt="zoom-logo" src={ClassRoom} className="w-75 mt-3 mb-3"></img>
                      </Col>
                      <Col md={9} className="text-justify">
                        <h5><strong>Google Classrooms</strong></h5>
                        <p>Utilizaremos una herramienta que ya conoces para que tengas todo el material tanto de videos pre-grabados y grabaciones de calses realizadas asi como para asignarte tus trabajos, ejercicios y exámenes</p>
                      </Col>
                    </Row>
                  </div>
                  <div className="course-tool p-4 mt-5 mb-4">
                  <Row>
                      <Col md={3} className="text-center">
                        <img alt="zoom-logo" src={Zoom} className="w-75 mt-3 mb-3"></img>
                      </Col>
                      <Col md={9} className="text-justify">
                        <h5><strong>Zoom meetings</strong></h5>
                        <p>Para la metodología Semi Asíncrona, estarás llevando clases en los horarios y fechas indicadas a travez de esta herramienta, para que no te pierdas ni una sola sesión e incluso la puedas verlas desde tu teléfono.</p>
                      </Col>
                    </Row>
                  </div>
                </Fragment>
                }
                <ReactMarkdown className="mt-5 mb-5 text-justify" source={markdown} />
              </Fragment>
                :
                <Skeleton/> 
            }
            
            { 
              chapters.length > 0 ?
              <Fragment>
                <div className="course-chapters mt-5 mb-5">
                  <h3 className="mt-5 mb-5"><strong>¿Que vas a aprender?</strong></h3>                
                  <div className={classes.root}>
                  {
                    chapters.map((i, key )=>(
                      <Accordion className="mt-4 mb-4 pt-3 pb-3" key={key}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}><strong>Capítulo {key + 1}: {i['title']}</strong></Typography>
                        </AccordionSummary>                      
                        {
                          i['lessons'].map((index, key1 )=>(
                            <AccordionDetails className="mt-3 mb-2" key={key+key1}>
                            <Typography>
                              Lección {(key+1) + "." +(key1 + 1)}: {index['title']}
                            </Typography>
                            </AccordionDetails>
                          ))
                        }                    
                      </Accordion>                  
                    ))
                  }
                  </div>
              </div>

              </Fragment> :
              <Skeleton count={5}/>
            }
          </Col>
          <Col md={5}>
          {currentUser ? <div></div>: <Alert variant="danger">Inicia sesión para matricularte</Alert>}
          {
            data.length > 0 ? 
            <Fragment>
            <div class="sticky-top course-sidebar p-3">
              <ReactPlayer 
                controls = "true"
                width='100%'
                height="300px"
                url={data[0]["video"]} />
              <h4 className="pt-3"><strong>{data[0]["title"]}</strong></h4>
              <h5 className="text-center pt-3"><strong>Precio</strong></h5>
              <div className="ad-price pt-3 pb-3 d-flex justify-content-center"> 
                <h4 className="pr-2 d-flex align-items-center pl-2 ad-comparition-price"><strike><strong>S/. {data[0]['discount_price']}</strong></strike></h4>
                <h2 className="pl-2 ad-real-price"><strong>S/. {data[0]['price']}</strong></h2>
              </div>
              <Link to={"/suscribir/"+id}><button className="btn btn-primary btn-block pt-3 pb-3"><strong>Inscribirme</strong></button></Link>
              
              <FacebookShareButton url={'https://welivic.com//cursos/' + id} className="w-100">
                <button className="btn btn-light btn-block pt-3 pb-3 mt-3" style={{color: '#97c7ff'}}>
                  <strong><Icon icon={shareAltOutlined} style={{color: '#97c7ff', fontSize: '25px'}} /> Compartir</strong>
                </button>
              </FacebookShareButton>
            </div>

            </Fragment>
            :
            <Skeleton count={5}/> 
          }

          </Col>
        </Row>
    </Container>
    </div>
  );
};

export default CourseView;